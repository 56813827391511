import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalContent from 'common/Modal/components/ModalContent';
import SearchInput from 'Shortcuts/AddToEvent/components/Modal/SearchInput';
import EventsHeader from 'Shortcuts/AddToEvent/components/Modal/EventsHeader';
import Loader from 'Shortcuts/AddToEvent/components/Loader';
import SelectedLists from './components/SelectedLists';
import Lists from './components/Lists';

export default class CopySubscribers extends Component {
	static propTypes = {
		activeLists: PropTypes.array,
		deselectAllSelected: PropTypes.func,
		hasAllSelected: PropTypes.bool,
		hideModal: PropTypes.func,
		isFetching: PropTypes.bool,
		isSelectedListsOpen: PropTypes.bool,
		isSubmiting: PropTypes.bool,
		onChange: PropTypes.func,
		onClear: PropTypes.func,
		onDeselectAll: PropTypes.func,
		onListChange: PropTypes.func,
		onSelectAll: PropTypes.func,
		onSubmit: PropTypes.func,
		timeFrame: PropTypes.string,
		openSelected: PropTypes.func,
		closeSelected: PropTypes.func,
		selectedLists: PropTypes.array,
		selectedListIds: PropTypes.array,
		subscribers: PropTypes.arrayOf( PropTypes.object ),
	};

	static defaultProps = {
		timeFrame: '',
	};

	render() {
		const numSubscribers = this.props.subscribers.length;
		return (
			<ModalContent
				title={ `Add ${ numSubscribers } Subscriber(s) to Another List` }
				hideModal={ this.props.hideModal }
				id={ 'modal__add-to-event' } // TODO: Fix CSS override
				className="modal--add-to-event" // TODO: Fix CSS override
			>
				<SearchInput onChange={ this.props.onChange } onClear={ this.props.onClear } placeholder="Search for lists" />
				<EventsHeader
					onSelectAllClick={ this.props.onSelectAll }
					onDeselectAllClick={ this.props.onDeselectAll }
					hasAllSelected={ this.props.hasAllSelected }
					timeFrame={ this.props.timeFrame }
					showTimeframe={ false }
				/>
				<div id="current-list-parent" className=" search__events-list search__events-list--current">
					<Lists
						lists={ this.props.activeLists }
						selected={ this.props.selectedListIds }
						onChange={ this.props.onListChange }
					/>
				</div>

				<Loader show={ this.props.isFetching } />
				<SelectedLists
					lists={ this.props.selectedLists }
					selected={ this.props.selectedListIds }
					openSelected={ this.props.openSelected }
					closeSelected={ this.props.closeSelected }
					isOpen={ this.props.isSelectedListsOpen }
					deselectAllSelected={ this.props.deselectAllSelected }
					onSubmit={ this.props.onSubmit }
					isSubmiting={ this.props.isSubmiting }
				/>

			</ModalContent>
		);
	}
}

import React from 'react';
import PropTypes from 'prop-types';
import { __ } from 'utils/translations';
import { LIST_EDITOR_SETTINGS } from 'config/settings';
import Card from 'common/Card';
import Button from 'common/Button';
import Warning from 'common/Warning';
import ModalContent from 'common/Modal/components/ModalContent';
import IndividualSubscribers from 'AudienceListCreator/ListCreator/ListSubscribers/components/IndividualSubscribers';
import CopyPasteSubscribers from 'AudienceListCreator/ListCreator/ListSubscribers/components/CopyPasteSubscribers';
import CSVSubscribers from 'AudienceListCreator/ListCreator/ListSubscribers/components/CSVSubscribers';
import styles from 'AudienceListCreator/ListCreator/ListSubscribers/components/index.pcss';
import contentStyles from './content.pcss';

const AddSubscribers = ( {
	setCSVTab,
	setCopyPasteTab,
	setIndividualTab,
	updateCopyPasteSubscribers,
	updateIndividualSubscriber,
	addIndividualSubscriber,
	removeIndividualSubscriber,
	isCSVTabActive,
	isCopyPasteTabActive,
	isIndividualTabActive,
	copyPasteSubscribers,
	individualSubscribers,
	csvImportSuccess,
	csvImportError,
	hideModal,
	hasValidSubscribers,
	onSubmit,
	listTitle,
	hasReachedUserUploadLimit,
	showCSVFormatError,
} ) => {
	return (
		<ModalContent
			title={ `Add Subscribers to "${ listTitle }" List` }
			hideModal={ hideModal }
		>
			<h1 className={ contentStyles.title }>Add Subscribers</h1>
			<p className={ contentStyles.subtitle }>Choose how to add people to your list.</p>
			<section className={ styles.cards }>
				<Card
					className={ styles.card }
					onClick={ setCSVTab }
					title="Import .CSV"
					icon="import-csv.svg"
					isActive={ isCSVTabActive }
				/>
				<Card
					className={ styles.card }
					onClick={ setCopyPasteTab }
					title="Copy & Paste Email Addresses"
					mobileTitle="Paste Email"
					icon="copy-paste.svg"
					isActive={ isCopyPasteTabActive }
				/>
				<Card
					className={ styles.card }
					onClick={ setIndividualTab }
					title="Add Individual Subscribers"
					mobileTitle="Add Individuals"
					icon="add-individuals.svg"
					isActive={ isIndividualTabActive }
				/>
			</section>
			<section>
				{
					isIndividualTabActive && (
						<IndividualSubscribers
							subscribers={ individualSubscribers }
							onChange={ updateIndividualSubscriber }
							addIndividualSubscriber={ addIndividualSubscriber }
							removeIndividualSubscriber={ removeIndividualSubscriber }
						/>
					)
				}
				{
					isCopyPasteTabActive && (
						<CopyPasteSubscribers
							subscribers={ copyPasteSubscribers }
							onChange={ updateCopyPasteSubscribers }
						/>
					)
				}
				{
					isCSVTabActive && (
						<CSVSubscribers
							onFileLoaded={ csvImportSuccess }
							onError={ csvImportError }
							showCSVFormatError={ showCSVFormatError }
						/>
					)
				}

			</section>
			{
				hasReachedUserUploadLimit && (
					<section>
						<Warning>
							{ __( 'warnings.errors-add-subscribers-modal-limit', { limit: LIST_EDITOR_SETTINGS.subscribers_limit } ) }
						</Warning>
					</section>
				)
			}
			<footer className={ contentStyles.footer }>
				<Button
					label="Add Subscribers"
					name="create"
					onClick={ onSubmit }
					type="blue"
					isActive={ hasValidSubscribers }
				/>
				<Button
					label="Cancel"
					name="cancel"
					onClick={ hideModal }
					type="tertiary"
				/>
			</footer>
		</ModalContent>
	);
};

AddSubscribers.propTypes = {
	setCSVTab: PropTypes.func.isRequired,
	setCopyPasteTab: PropTypes.func.isRequired,
	setIndividualTab: PropTypes.func.isRequired,
	updateCopyPasteSubscribers: PropTypes.func.isRequired,
	updateIndividualSubscriber: PropTypes.func.isRequired,
	addIndividualSubscriber: PropTypes.func.isRequired,
	removeIndividualSubscriber: PropTypes.func.isRequired,
	isCSVTabActive: PropTypes.bool.isRequired,
	isCopyPasteTabActive: PropTypes.bool.isRequired,
	isIndividualTabActive: PropTypes.bool.isRequired,
	hasReachedUserUploadLimit: PropTypes.bool.isRequired,
	hasValidSubscribers: PropTypes.bool.isRequired,
	csvSubscribers: PropTypes.arrayOf( PropTypes.object ),
	copyPasteSubscribers: PropTypes.arrayOf( PropTypes.object ),
	individualSubscribers: PropTypes.arrayOf( PropTypes.object ),
	csvImportSuccess: PropTypes.func.isRequired,
	csvImportError: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	listTitle: PropTypes.string.isRequired,
	showCSVFormatError: PropTypes.bool.isRequired,
};

export default AddSubscribers;

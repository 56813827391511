import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Label from 'common/Label';
import MultiEmailInput from './MultiEmailInput';
import styles from './index.pcss';

const CopyPasteSubscribers = ( { subscribers, onChange } ) => {
	return (
		<Fragment>
			<Label className={ styles.header }>Paste Email Addresses</Label>
			<MultiEmailInput
				emails={ subscribers.map( subscriber => subscriber.email ) }
				onChange={ onChange }
			/>
		</Fragment>
	);
};

CopyPasteSubscribers.propTypes = {
	subscribers: PropTypes.arrayOf( PropTypes.object ),
	onChange: PropTypes.func.isRequired,
};

export default CopyPasteSubscribers;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import styles from './components/modal.pcss';

export default class ModalBase extends PureComponent {
	static propTypes = {
		appElement: PropTypes.object,
		content: PropTypes.string.isRequired,
		onAfterOpen: PropTypes.func,
		hideModal: PropTypes.func.isRequired,
		onConfirmAction: PropTypes.func,
		modalStyles: PropTypes.object,
		modalTypes: PropTypes.objectOf( PropTypes.func ),
		className: PropTypes.string,
		overlayClassName: PropTypes.string,
		modalFull: PropTypes.bool,
	};

	static defaultProps = {
		content: '',
		isOpen: false,
		modalFull: true,
		modalTypes: {},
		modalStyles: {
			overlay: {
				backgroundColor: 'white',
			},
		},
	};

	handleClose = () => {
		this.props.hideModal();
	};

	handleOpen = () => {
		this.preventBodyScroll();
		this.props.onAfterOpen && this.props.onAfterOpen();
	};

	preventBodyScroll = () => document.body.setAttribute( 'style', 'overflow: hidden' );
	allowBodyScroll = () => document.body.removeAttribute( 'style' );

	render() {
		const { content } = this.props;

		if ( typeof this.props.modalTypes[ content ] === 'undefined' ) {
			return null;
		}

		const ModalContent = this.props.modalTypes[ content ];

		return (
			<ReactModal
				appElement={ this.props.appElement }
				isOpen={ !! content }
				onAfterOpen={ this.handleOpen }
				onAfterClose={ this.allowBodyScroll }
				onRequestClose={ this.handleClose }
				overlayClassName={
					classNames( styles.modalcontain, {
						[ styles.modalfull ]: this.props.modalFull,
					}, this.props.overlayClassName )
				}
				className={
					classNames( styles.modalcontent, this.props.className )
				}
				style={ this.props.modalStyles }
			>
				<ModalContent { ...this.props } />
			</ReactModal>
		);
	}
}

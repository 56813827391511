import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './index.pcss';

const Loader = ( {
	show,
} ) => {
	return (
		<section className={
			classNames( 'search__loader', {
				show: show,
				[ styles.hide ]: ! show,
			} ) }
		>
			<div className="loader">
				<div className="bubble bubble--1"></div>
				<div className="bubble bubble--2"></div>
				<div className="bubble bubble--3"></div>
				<div className="bubble bubble--4"></div>
			</div>
		</section>
	);
};

Loader.propTypes = {
	show: PropTypes.bool,
};

export default Loader;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylesText from 'common/Text.pcss';
import styles from './Warning.pcss';

const Warning = ( { children, className } ) => (
	<span className={ classNames( stylesText.errormessage, styles.container, className ) }>
		{ children }
	</span>
);

Warning.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

Warning.defaultProps = {
	className: '',
};

export default Warning;

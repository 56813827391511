import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Text.pcss';
import overrideStyles from './MockInput.pcss';

const MockInput = ( { children, className } ) => {
	return (
		<span className={ classNames( styles.textinput, overrideStyles.MockInput, className ) }>
			{ children }
		</span>
	);
};

MockInput.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default MockInput;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './SelectAllButton.pcss';

const SelectAllButton = ( {
	onSelectAllClick,
	onDeselectAllClick,
	hasAllSelected,
	hideOnMobile,
} ) => (
	<button
		type="button"
		className={ classNames( styles.button, { [ styles.hideMobile ]: hideOnMobile } ) }
		onClick={ hasAllSelected ? onDeselectAllClick : onSelectAllClick }
	>
		{ hasAllSelected ? 'Deselect All' : 'Select All' }
	</button>
);

SelectAllButton.propTypes = {
	hasAllSelected: PropTypes.bool,
	onSelectAllClick: PropTypes.func,
	onDeselectAllClick: PropTypes.func,
	hideOnMobile: PropTypes.bool,
};

export default SelectAllButton;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'common/Button';
import ModalContent from 'common/Modal/components/ModalContent';
import { IMAGES_URL } from 'config/settings';
import SelectMod from 'apps/common/Select';
import modalStyles from 'common/Modal/components/modal.pcss';

export default class Delete extends Component {
	static propTypes = {
		subscribers: PropTypes.arrayOf( PropTypes.object ),
		hideModal: PropTypes.func,
		onSubmit: PropTypes.func,
		modalTitleSuffix: PropTypes.string,
		modalContentSuffix: PropTypes.string,
	};

	static defaultProps = {
		modalTitleSuffix: '',
		modalContentSuffix: '',
	}

	state = {
		validConfirm: false,
	};

	confirmOptions = [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' },
	];

	handleConfirmValidation = ( e ) => this.setState( { validConfirm: e } );

	render() {
		return (
			<ModalContent
				title={ `Delete "${ this.props.modalTitleSuffix }"` }
				hideModal={ this.props.hideModal }
			>
				<div className={ modalStyles.flexedcontent }>
					<div className={ classNames( modalStyles.modal__columnleft, modalStyles.modal__columnleftsend ) }>
						<img
							className={ modalStyles.modal__image }
							src={ `${ IMAGES_URL }/delete-subs.png` }
							alt=""
						/>
					</div>
					<div className={ modalStyles.modal__columnright }>
						<h2 className={ modalStyles.modal__contenttitle }>
							You're about to delete the "{ this.props.modalTitleSuffix }" list
						</h2>
						<div className={ modalStyles.modal__select }>
							<div className={ modalStyles.modal__selectlabel }>Delete this list</div>
							<SelectMod name="confirmCheck" options={ this.confirmOptions } onChange={ this.handleConfirmValidation } isActive={ true } value={ this.state.validConfirm } />
						</div>
						<div className={ modalStyles.btngroup }>
							<Button type="red" label="Delete Now" name="delete" onClick={ this.props.onSubmit } isActive={ this.state.validConfirm } />
							<Button type="tertiary" label="Cancel" name="cancel" onClick={ this.props.hideModal } />
						</div>
					</div>
				</div>

			</ModalContent>
		);
	}
}

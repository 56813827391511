import map from 'lodash/map';
import { fork, all, call, put, take, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import addSubscribersModal from 'Modals/Subscribers/AddSubscribers/sagas';
import { getValidActiveSubscribers } from 'Modals/Subscribers/AddSubscribers/ducks';
import { showAddSubscribers, showDeleteList, SUBMIT_DELETE_LIST, SUBMIT_SUBSCRIBERS, showEditListSettings, SUBMIT_EDIT_LIST_SETTINGS } from './ducks/modal';
import { setActiveList, getListId, getListFooter, getListColor, getListTitle } from './ducks/list';
import * as service from './service';
import * as tools from 'utils/tools';
import { LISTS_ENDPOINT } from 'config/settings';
import { createListError } from 'AudienceListCreator/ListCreator/ducks/index';

function setActiveListChannel() {
	return eventChannel( emitter => {
		function onClick( e ) {
			const template = e.currentTarget.querySelector( 'template' );
			const json = JSON.parse( template.innerHTML );

			emitter( json );
		}

		const rows = Array.from( document.querySelectorAll( '.lists__table-row' ) );
		rows.forEach( row => row.addEventListener( 'click', onClick ) );

		return () => rows.forEach( row => row.removeEventListener( 'click', onClick ) );
	}
	);
}

/**
 *	Interface connecting delete list button clicks
 *
 * @returns Channel
 */
function openDeleteListModalChannel() {
	return eventChannel( emitter => {
		function onClick() {
			emitter( true );
		}

		const deleteButtons = Array.from( tools.getNodes( 'delete-list' ) );
		deleteButtons.forEach( button => button.addEventListener( 'click', onClick ) );

		return () => {
			deleteButtons.forEach( button => button.removeEventListener( 'click', onClick ) );
		};
	}
	);
}

/**
 *	Interface connecting shortcut card clicks to redux saga
 *
 * @returns Channel
 */
function openAddSubscriberModalChannel() {
	return eventChannel( emitter => {
		function onClick() {
			emitter( true );
		}

		const addButtons = Array.from( document.querySelectorAll( '.lists__list-add-subscriber-button' ) );
		const addButtonsMobile = Array.from( document.querySelectorAll( '.lists__list-menu-item.lists__list-menu-item--add' ) );
		addButtons.forEach( button => button.addEventListener( 'click', onClick ) );
		addButtonsMobile.forEach( button => button.addEventListener( 'click', onClick ) );

		return () => {
			addButtons.forEach( button => button.removeEventListener( 'click', onClick ) );
			addButtonsMobile.forEach( button => button.removeEventListener( 'click', onClick ) );
		};
	}
	);
}

/**
 *	Interface connecting shortcut card clicks to redux saga
 *
 * @returns Channel
 */
function openListEditorSettingsChannel() {
	return eventChannel( emitter => {
		function onClick() {
			emitter( true );
		}

		const addButtons = Array.from( document.querySelectorAll( '.lists__list-menu-item.lists__list-menu-item--settings > button' ) );
		addButtons.forEach( button => button.addEventListener( 'click', onClick ) );

		return () => addButtons.forEach( button => button.removeEventListener( 'click', onClick ) );
	}
	);
}

/**
 * Saga receiving channel emits and updating store
 *
 * @export
 */
export function* setActiveListListener() {
	const channel = yield call( setActiveListChannel );
	while ( true ) {
		const payload = yield take( channel );
		yield put( setActiveList( payload ) );
	}
}

/**
 * Saga receiving channel emits and updating store
 *
 * @export
 */
export function* showAddSubscriberModalListener() {
	const channel = yield call( openAddSubscriberModalChannel );
	while ( true ) {
		yield take( channel );
		yield put( showAddSubscribers() );
	}
}

/**
 * Saga receiving channel emits and updating store
 *
 * @export
 */
export function* showDeleteListModalListener() {
	const channel = yield call( openDeleteListModalChannel );
	while ( true ) {
		yield take( channel );
		yield put( showDeleteList() );
	}
}

/**
 * Saga receiving channel emits and updating store
 *
 * @export
 */
export function* showListEditorSettingsModalListener() {
	const channel = yield call( openListEditorSettingsChannel );
	while ( true ) {
		yield take( channel );
		yield put( showEditListSettings() );
	}
}

export function* handleOnAddSubscriberModalSubmit() {
	while ( true ) {
		yield take( SUBMIT_SUBSCRIBERS );
		try {
			const listId = yield select( getListId );
			const subscribers = yield select( getValidActiveSubscribers );
			yield call( service.updateListWithSubscribers, listId, { subscribers } );
			yield call( [ window.location, 'reload' ] );
		} catch ( error ) {
			tools.reporter( error );
			yield put( createListError( error ) );
		}
	}
}

export function* updateListSettings() {
	const listId = yield select( getListId );
	const payload = yield all( {
		title: select( getListTitle ),
		color: select( getListColor ),
		footer: select( getListFooter ),
	} );
	yield call( service.updateListSettings, listId, payload );
}

export function* handleOnListEditSettingsModalSubmit() {
	while ( true ) {
		yield take( SUBMIT_EDIT_LIST_SETTINGS );
		try {
			yield call( updateListSettings );
			yield call( [ window.location, 'reload' ] );
		} catch ( error ) {

		}
	}
}

export function* handleOnListDeleteModalSubmit() {
	while ( true ) {
		yield take( SUBMIT_DELETE_LIST );
		try {
			const listId = yield select( getListId );
			yield call( service.deleteList, listId );
			window.location.href = LISTS_ENDPOINT;
		} catch ( error ) {

		}
	}
}

export const childSagas = [
	setActiveListListener,
	showAddSubscriberModalListener,
	handleOnAddSubscriberModalSubmit,
	showListEditorSettingsModalListener,
	handleOnListEditSettingsModalSubmit,
	showDeleteListModalListener,
	handleOnListDeleteModalSubmit,
	addSubscribersModal,
];

export function* mainSaga( childrenSagas ) {
	yield all( [
		...map( childrenSagas, childSaga => ( fork( childSaga ) ) ),
	] );
}

import { call, put, takeEvery } from 'redux-saga/effects';
import { flatMap, filter, uniqBy } from 'lodash';
import { pipe } from 'lodash/fp';
import {
	CSV_IMPORT_SUCCESS,
	CSV_IMPORT_ERROR,
	updateCSVImportSubscibers,
} from '../ducks/addSubscribers';
import subscriberFactory from '../factories/subscriber';

const getSubscribers = pipe(
	rows => rows.slice( 1 ), // Remove header from CSV
	rows => flatMap(
		rows,
		( [ firstName, lastName, email ] ) => subscriberFactory( { email, firstName, lastName } )
	),
	subscribers => filter( subscribers, subscriber => !! subscriber.email ),
	subscribers => uniqBy( subscribers, 'email' )
);

export function* handleCSVImportSuccess( action ) {
	const subscribers = yield call( getSubscribers, action.payload );
	yield put( updateCSVImportSubscibers( subscribers ) );
}

export function* handleCSVImportError() {
}

export default function* root() {
	yield takeEvery( CSV_IMPORT_SUCCESS, handleCSVImportSuccess );
	yield takeEvery( CSV_IMPORT_ERROR, handleCSVImportError );
}

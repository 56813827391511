import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { isEmail } from 'react-multi-email';
import Text from 'common/Text';
import Button from 'common/Button';
import styles from './IndividualSubscriber.pcss';

export default class IndividualSubscriber extends PureComponent {
	static propTypes = {
		email: PropTypes.string,
		firstName: PropTypes.string,
		id: PropTypes.string,
		index: PropTypes.number.isRequired,
		lastName: PropTypes.string,
		onChange: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		showDeleteButton: PropTypes.func,
	}

	constructor( props ) {
		super( props );

		this.state = {
			email: props.email,
			firstName: props.firstName,
			lastName: props.lastName,
			id: props.id,
		};
	}

	onChange = debounce( () => {
		this.props.onChange( this.state );
	}, 250 )

	handleEmailChange = ( e ) => {
		this.setState( { email: e.currentTarget.value }, this.onChange );
	}

	handleFirstNameChange = ( e ) => {
		this.setState( { firstName: e.currentTarget.value }, this.onChange );
	}

	handleLastNameChange = ( e ) => {
		this.setState( { lastName: e.currentTarget.value }, this.onChange );
	}

	validateEmail = ( e ) => isEmail( e.currentTarget.value )

	render() {
		return [
			<div key={ `${ this.props.index }-0` } className={ styles.IndividualSubscriber }>
				<div className={ styles.column }>
					<Text
						labelClassName="a11y-visual-hide"
						name={ `firstName[${ this.props.index }]` }
						onChange={ this.handleFirstNameChange }
						value={ this.state.firstName }
						placeholder="First Name"
					/>
				</div>
				<div className={ styles.column }>
					<Text
						labelClassName="a11y-visual-hide"
						name={ `lastName[${ this.props.index }]` }
						onChange={ this.handleLastNameChange }
						value={ this.state.lastName }
						placeholder="Last Name"
					/>
				</div>
				<div className={ classNames( styles.column, styles.email ) }>
					<Text
						labelClassName="a11y-visual-hide"
						name={ `email[${ this.props.index }]` }
						onChange={ this.handleEmailChange }
						value={ this.state.email }
						placeholder="Email"
						validate={ this.validateEmail }
					/>
				</div>
				{
					this.props.showDeleteButton && (
						<Button
							onClick={ this.props.onDelete }
							type="delete"
							className={ styles.deleteButton }
						/>
					)
				}
			</div>,
			this.props.showDeleteButton && <hr key={ `${ this.props.index }-1` } className={ styles.divider } />,
		];
	}
}

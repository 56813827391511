import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import styles from './Card.pcss';

const Card = ( {
	cardTitle,
	className,
	disabledTooltip,
	icon,
	imgPath,
	isActive,
	onClick,
	title,
	mobileTitle,
	description,
} ) => {
	return (
		<button
			className={ classNames(
				styles.card,
				'card',
				'card--alt',
				'swiper-slide',
				{
					active: isActive,
					disabled: disabledTooltip,
					[ styles.hasMobileTitle ]: !! mobileTitle,
				},
				className,
			) }
			onClick={ onClick }
		>
			{
				!! imgPath && !! icon && (
					<img src={ `${ imgPath }/${ icon }` } alt="" />
				)
			}
			<span className={ classNames( 'CardTitle', styles.title ) }>{ cardTitle || title }</span>
			{
				!! mobileTitle && (
					<span className={ styles.mobileTitle }>{mobileTitle}</span>
				)
			}
			<span className={ classNames( 'CardDescription', styles.description ) }>{ description }</span>
			{!! disabledTooltip && disabledTooltip}
		</button>
	);
};

Card.defaultProps = {
	title: '',
	description: '',
	imgPath: '/images/svg',
	disabledTooltip: null,
	onClick: noop,
};

Card.propTypes = {
	title: PropTypes.string.isRequired,
	mobileTitle: PropTypes.string,
	cardTitle: PropTypes.string,
	description: PropTypes.string,
	icon: PropTypes.string,
	isActive: PropTypes.bool.isRequired,
	onClick: PropTypes.func,
	className: PropTypes.string,
	imgPath: PropTypes.string,
	disabledTooltip: PropTypes.node,
};

export default Card;

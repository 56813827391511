/**
 * @module
 * @description List Service
 */
import axios from 'axios';
import { LISTS_ENDPOINT } from 'config/settings';
import { reporter } from 'utils/tools';
import { route, getQueryString } from 'common/api';

export async function unsubscriberSubscribersFromList( id, payload ) {
	try {
		return await axios.put( `${ LISTS_ENDPOINT }/${ id }/subscriptions/bulk`, payload );
	} catch ( e ) {
		reporter( e );
	}
}

export async function copySubscribersToList( id, payload ) {
	try {
		return await axios.post( `${ LISTS_ENDPOINT }/${ id }/copy`, payload );
	} catch ( e ) {
		reporter( e );
	}
}

export async function addSubscribersToList( id, payload ) {
	return await axios.post( `${ LISTS_ENDPOINT }/${ id }/subscriptions/bulk`, payload );
}

export async function deleteSubscribersFromList( id, payload ) {
	try {
		await axios.delete( `${ LISTS_ENDPOINT }/${ id }/subscriptions/bulk`, { data: payload } );
	} catch ( e ) {
		reporter( e );
	}
}

/**
 *	Fetch lists for user
 *
 * @export
 * @param {Object} params
 * @returns lists from response
 */
export async function fetchLists( params ) {
	try {
		const queryString = getQueryString( params );
		const query = queryString ? `?${ queryString }` : '';
		return await route( `search/lists/${ query }` );
	} catch ( e ) {
		reporter( e );
		return {};
	}
}

import { connect } from 'react-redux';
import { updateTitle, updateColor, updateFooter } from 'AudienceListCreator/ListCreator/ducks';
import Content from './content';

export default connect(
	null,
	dispatch => ( {
		updateTitle: e => dispatch( updateTitle( e.currentTarget.value ) ),
		updateColor: color => dispatch( updateColor( color ) ),
		updateFooter: footer => dispatch( updateFooter( footer ) ),
	} )
)( Content );

/**
 * @module
 * @description Delete Service
 */
import axios from 'axios';
import { SUBSCRIBERS_ENDPOINT } from 'config/settings';

export { addSubscribersToList, fetchLists, copySubscribersToList } from '../List/service';
import { reporter } from 'utils/tools';

export async function unsubscriberSubscribers( payload ) {
	try {
		return await axios.put( `${ SUBSCRIBERS_ENDPOINT }/bulk`, payload );
	} catch ( e ) {
		reporter( e );
	}
}

export async function deleteSubscribers( payload ) {
	try {
		return await axios.delete( `${ SUBSCRIBERS_ENDPOINT }/bulk`, { data: payload } );
	} catch ( e ) {
		reporter( e );
	}
}

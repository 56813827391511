import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './InfoBar.pcss';

const InfoBar = ( { children, className } ) => {
	return (
		<span className={ classNames( styles.InfoBar, className ) }>
			{ children }
		</span>
	);
};

InfoBar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default InfoBar;

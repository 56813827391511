// ES module-style import
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import PropTypes from 'prop-types';

let ErrorBoundary;
if ( window.bugsnagClient ) {
	window.bugsnagClient.use( bugsnagReact, React );
	ErrorBoundary = window.bugsnagClient.getPlugin( 'react' );
} else {
	ErrorBoundary = ( props ) => props.children;
}

const AppBoundary = ( { children } ) => {
	return (
		<ErrorBoundary>
			{ children }
		</ErrorBoundary>
	);
};

AppBoundary.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AppBoundary;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';
import { GLOBAL } from 'config/settings';

const defaultConfig = {
	plugins: 'autolink legacyoutput',
	toolbar: 'bold italic underline',
	content_css: GLOBAL.css_path,
	statusbar: false,
};

const Editor = ( {
	id,
	content,
	config,
	onChange,
	onFocus,
} ) => {
	useEffect( () => {
		if ( ! window.tinymce ) {
			return;
		}

		// Make sure to get the right theme file;
		window.tinymce.suffix = '.min';
		// Set the baseURL in order to correctly include tinymce-related assets
		window.tinymce.baseURL = '/vendor/tinymce';
	}, [] );

	return (
		<TinyMCE
			id={ id }
			initialValue={ content }
			init={ { ...defaultConfig, ...config } }
			onEditorChange={ onChange }
			onFocus={ onFocus }
		/>
	);
};

Editor.propTypes = {
	id: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	config: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
};

Editor.defaultProps = {
	config: {},
	onFocus: noop,
};

export default Editor;

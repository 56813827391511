import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Label from 'common/Label';
import Button from 'common/Button';
import IndividualSubscriber from './IndividualSubscriber';
import styles from './index.pcss';

const IndividualSubscribers = ( { subscribers, onChange, addIndividualSubscriber, removeIndividualSubscriber } ) => {
	return (
		<Fragment>
			<Label className={ styles.header }>Add Subscribers</Label>
			{
				subscribers.map( ( subscriber, index ) => {
					return ( <IndividualSubscriber
						key={ subscriber.id }
						id={ subscriber.id }
						email={ subscriber.email }
						firstName={ subscriber.firstName }
						lastName={ subscriber.lastName }
						onChange={ onChange( index ) }
						index={ index }
						onDelete={ removeIndividualSubscriber( index ) }
						showDeleteButton={ index < subscribers.length - 1 }
					/> );
				} )

			}
			<Button
				type="inline"
				label="+ Add Another Subscriber"
				onClick={ addIndividualSubscriber }
				className={ styles.addButton }
			/>
		</Fragment>
	);
};

IndividualSubscribers.propTypes = {
	subscribers: PropTypes.arrayOf( PropTypes.object ),
	onChange: PropTypes.func.isRequired,
	addIndividualSubscriber: PropTypes.func.isRequired,
	removeIndividualSubscriber: PropTypes.func.isRequired,
};

export default IndividualSubscribers;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import styles from './Color.pcss';

const Color = ( {
	className,
	isActive,
	onClick,
	color,
} ) => {
	return (
		<button
			className={ classNames( styles.Color, className, {
				[ styles.active ]: isActive,
			} ) }
			onClick={ onClick }
			type="button"
			style={ { backgroundColor: color } }
		>
			&nbsp;
		</button>
	);
};

Color.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	isActive: PropTypes.bool.isRequired,
};

Color.defaultProps = {
	onClick: noop,
};

export default Color;

/* eslint-disable react/jsx-no-bind */
import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import styles from './MultiEmailInput.pcss';

export default class MultiEmailInput extends PureComponent {
	static propTypes = {
		emails: PropTypes.arrayOf( PropTypes.string ),
		onChange: PropTypes.func.isRequired,
		onRemove: PropTypes.func.isRequired,
	}

	constructor( props ) {
		super( props );

		this.state = {
			emails: props.emails,
		};
	}

	container = createRef();

	handleChange = ( emails ) => {
		this.setState(
			{ emails },
			() => this.props.onChange( emails )
		);
	}

	handleClick = () => {
		try {
			this.container.current.querySelector( 'input' ).focus();
		} catch ( error ) {

		}
	}

	render() {
		return (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
			<div className={ styles.MultiEmailInput } ref={ this.container } onClick={ this.handleClick }>
				<ReactMultiEmail
					emails={ this.state.emails }
					onChange={ this.handleChange }
					validateEmail={ isEmail }
					getLabel={
						(
							email,
							index,
							removeEmail
						) => {
							return (
								<div className={ styles.tag } key={ index }>
									<span className={ styles.text }>
										{email}
									</span>
									<button className={ styles.remove }onClick={ () => removeEmail( index ) }>×</button>
								</div>
							);
						}

					}
				/>
			</div>
		);
	}
}

import React from 'react';
import PropTypes from 'prop-types';

export const WithMediaHOC = ( Component ) => {
	class WithMedia extends React.Component {
		bindChange = () => {
			if ( this.mql.addEventListener ) {
				this.mql.addEventListener( 'change', this.onChange );
			} else {
				this.mql.addListener( this.onChange );
			}
		}

		unbindChange = () => {
			if ( this.mql.removeEventListener ) {
				this.mql.removeEventListener( 'change', this.onChange );
			} else {
				this.mql.removeListener( this.onChange );
			}
		}

		componentDidMount() {
			const { feature, callback } = this.props.mediaConfig;
			this.onChange = callback;
			this.mql = window.matchMedia( feature );
			if ( this.mql.matches ) {
				this.onChange();
			}
			this.bindChange();
		}

		componentWillUnmount() {
			this.unbindChange();
		}

		render() {
			const { mediaConfig, ...rest } = this.props;
			return (
				<Component { ...rest } />
			);
		}
	}

	WithMedia.propTypes = {
		mediaConfig: PropTypes.shape( {
			feature: PropTypes.string.isRequired,
			callback: PropTypes.func.isRequired,
		} ).isRequired,
	};

	return WithMedia;
};

const withMedia = WithMediaHOC;

export default withMedia;

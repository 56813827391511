import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class SearchInput extends PureComponent {
	static propTypes = {
		searchText: PropTypes.string,
		onChange: PropTypes.func.isRequired,
		onClear: PropTypes.func.isRequired,
		placeholder: PropTypes.string,
	}

	static defaultProps = {
		placeholder: 'Search for Events',
	}

	constructor( props ) {
		super( props );

		this.state = {
			searchText: props.searchText,
		};
	}

	onChange = ( e ) => {
		this.props.onChange( e.target.value );
		this.setState( { searchText: e.target.value } );
	}

	onClear= () => {
		this.props.onClear( '' );
		this.setState( { searchText: '' } );
	}

	render() {
		return (
			<div className="input-group">
				<input data-js="event_search" id="event_search" className="form-control" name="event_search" placeholder={ this.props.placeholder } type="text" autoComplete="off" onChange={ this.onChange } value={ this.state.searchText } />
				{
					!! this.state.searchText && (
						<div className="form-control__clear">
							<button className="icon-close" aria-label="Clear search" type="button" onClick={ this.onClear }>
								<span className="icon-close-inner"></span>
							</button>
						</div>
					)
				}
			</div>
		);
	}
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './BlockNumber.pcss';

const outerClasses = classNames( {
	numbered: true,
	[ styles.span ]: true,
} );

const BlockNumber = ( { number } ) => (
	<div className={ styles.number }>
		<span className={ outerClasses }>{number}</span>
	</div>
);

BlockNumber.propTypes = {
	number: PropTypes.string,
};

BlockNumber.defaultProps = {
	number: '1',
};

export default BlockNumber;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Label.pcss';

const Label = ( { children, className, ...restProps } ) => {
	return (
		// eslint-disable-next-line jsx-a11y/label-has-for
		<label className={ classNames( styles.Label, className ) } { ...restProps }>
			{ children }
		</label>
	);
};

Label.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default Label;

import { connect } from 'react-redux';
import { curry } from 'lodash/fp';
import {
	getActiveSubscriberTab,
	isCSVTabActive,
	isCopyPasteTabActive,
	isIndividualTabActive,
	getCSVSubscribers,
	getCopyPasteSubscribers,
	getIndividualSubscribers,
	hasValidActiveSubscribers,
	setActiveSubscriberType,
	addIndividualSubscriber,
	removeIndividualSubscriber,
	updateIndividualSubscriber,
	updateCopyPasteSubscribers,
	csvImportSuccess,
	csvImportError,
	showCSVFormatError,
} from 'AudienceListCreator/ListCreator/ducks/addSubscribers';
import {
	hasReachedUserUploadLimit,
} from 'AudienceListCreator/ListCreator/ducks/errors';
import {
	CSV_SUBSCRIBERS,
	COPY_PASTE_SUBSCRIBERS,
	INDIVIDUAL_SUBSCRIBERS,
} from 'AudienceListCreator/ListCreator/constants/subscriber';
import Content from './content';

export default connect(
	state => ( {
		activeTab: getActiveSubscriberTab( state ),
		csvSubscribers: getCSVSubscribers( state ),
		copyPasteSubscribers: getCopyPasteSubscribers( state ),
		individualSubscribers: getIndividualSubscribers( state ),
		isCSVTabActive: isCSVTabActive( state ),
		isCopyPasteTabActive: isCopyPasteTabActive( state ),
		isIndividualTabActive: isIndividualTabActive( state ),
		hasValidSubscribers: hasValidActiveSubscribers( state ),
		hasReachedUserUploadLimit: hasReachedUserUploadLimit( state ),
		showCSVFormatError: showCSVFormatError( state ),
	} ),
	dispatch => ( {
		setCSVTab: () => dispatch( setActiveSubscriberType( CSV_SUBSCRIBERS ) ),
		setCopyPasteTab: () => dispatch( setActiveSubscriberType( COPY_PASTE_SUBSCRIBERS ) ),
		setIndividualTab: () => dispatch( setActiveSubscriberType( INDIVIDUAL_SUBSCRIBERS ) ),
		addIndividualSubscriber: () => dispatch( addIndividualSubscriber() ),
		removeIndividualSubscriber: ( index ) => () => dispatch( removeIndividualSubscriber( index ) ),
		updateIndividualSubscriber: curry( ( index, subscriber ) => dispatch( updateIndividualSubscriber( index, subscriber ) ) ),
		updateCopyPasteSubscribers: ( emails ) => dispatch( updateCopyPasteSubscribers( emails ) ),
		csvImportSuccess: ( data ) => dispatch( csvImportSuccess( data ) ),
		csvImportError: ( error ) => dispatch( csvImportError( error ) ),
	} )
)( Content );

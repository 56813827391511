import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { pipe, noop } from 'lodash';

import styles from './Dropdown.pcss';

const Dropdown = ( {
	options,
	value: currentValue,
	onChange,
	className,
	toggleClassName,
} ) => (
	<div className={ classnames( 'event-header-toggle', styles.dropdown, className ) }>
		<ul className={ classnames( 'toggle-list', styles.toggle, toggleClassName ) }>
			{
				options.map( ( { label, value, onSelect = noop } ) => (
					<li key={ value }>
						<button
							onClick={ pipe( onSelect, onChange.bind( this, value ) ) }
							className={ classnames( {
								active: currentValue === value,
							} ) }
						>
							{label}
						</button>
					</li>
				) )
			}
		</ul>
	</div>
);

Dropdown.propTypes = {
	options: PropTypes.arrayOf( PropTypes.shape( {
		label: PropTypes.string,
		value: PropTypes.any,
		onSelect: PropTypes.func,
	} ) ),
	value: PropTypes.any,
	onChange: PropTypes.func,
	className: PropTypes.string,
	toggleClassName: PropTypes.string,
};

export default Dropdown;

import React from 'react';

import styles from './Loading.pcss';

const Loader = () => (
	<section className={ styles.loading } data-js="section-loading">
		<div className="loader">
			<div className="bubble bubble--1"></div>
			<div className="bubble bubble--2"></div>
			<div className="bubble bubble--3"></div>
			<div className="bubble bubble--4"></div>
		</div>
	</section>
);

export default Loader;

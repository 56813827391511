/**
 * @module
 * @description Delete Service
 */
import axios from 'axios';
import { LISTS_ENDPOINT } from 'config/settings';
import { reporter } from 'utils/tools';

export async function updateListWithSubscribers( id, payload ) {
	return await axios.post( `${ LISTS_ENDPOINT }/${ id }/subscriptions/bulk`, payload );
}

export async function updateListSettings( id, payload ) {
	try {
		return await axios.put( `${ LISTS_ENDPOINT }/${ id }`, payload );
	} catch ( e ) {
		reporter( e );
	}
}

export async function deleteList( id ) {
	try {
		await axios.delete( `${ LISTS_ENDPOINT }/${ id }` );
	} catch ( e ) {
		reporter( e );
	}
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'common/Button';
import EventsHeader from 'Shortcuts/AddToEvent/components/Modal/EventsHeader';
import Lists from './Lists';
import styles from 'Shortcuts/AddToEvent/components/Modal/SelectedEvents.pcss';

const SelectedLists = ( {
	lists,
	selected,
	openSelected,
	closeSelected,
	deselectAllSelected,
	isOpen,
	isSubmiting,
	onSubmit,
} ) => {
	const deselectAndClose = () => {
		deselectAllSelected();
		closeSelected();
	};
	return (
		<footer className={ classNames( styles.SelectedEvents, {
			[ styles.open ]: isOpen,
			[ styles.shown ]: !! selected.length,
		} ) }>
			<section className={ styles.section }>
				<div className={ classNames( styles.column ) }>
					<button className={ styles.selectButton } type="button" onClick={ isOpen ? closeSelected : openSelected }>
						<span className={ styles.count }>
							<span>{selected.length}</span>
						</span>
						<span className={ styles.text }>
							Selected <span>Lists</span>
							<span className={ styles.arrow }></span>
						</span>
					</button>

					<Button
						className={ styles.desktopButton }
						type="blue"
						onClick={ onSubmit }
						isActive={ !! selected.length || ! isSubmiting }
						label="Add to Lists"
					/>
					<Button
						className={ styles.mobileButton }
						type="tertiary-blue"
						onClick={ onSubmit }
						isActive={ !! selected.length || ! isSubmiting }
						label="Add to Lists"
					/>
				</div>
			</section>

			<section className={ classNames( styles.section ) }>
				<EventsHeader
					onSelectAllClick={ deselectAndClose }
					onDeselectAllClick={ deselectAndClose }
					hasAllSelected
					showTimeframe={ false }
					timeFrame={ '' }
				/>

				<div className={ classNames( styles.events ) }>
					<Lists lists={ lists } selected={ selected } />
				</div>
			</section>
		</footer>
	);
};

SelectedLists.propTypes = {
	closeSelected: PropTypes.func.isRequired,
	closeSelectedEvents: PropTypes.func.isRequired,
	deselectAllSelected: PropTypes.func.isRequired,
	deselectAllSelectedEvents: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	isSubmiting: PropTypes.bool.isRequired,
	lists: PropTypes.arrayOf( PropTypes.object ),
	openSelected: PropTypes.func.isRequired,
	openSelectedEvents: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	selected: PropTypes.arrayOf( PropTypes.number ),
};

export default SelectedLists;

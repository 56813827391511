const genId = () => '_' + Math.random().toString( 36 ).substr( 2, 9 );

/**
 *
 *
 * @export
 * @class Subscriber
 */
export class Subscriber {
	constructor( email, first_name, last_name, id ) {
		this.email = email;
		this.first_name = first_name;
		this.last_name = last_name;
		this.id = id || genId();
	}

	toString() {
		return `${ this.email }-${ this.first_name }-${ this.last_name }`;
	}
}

/**
 * Create a subscriber object
 *
 * @export
 * @param {string} [email='']
 * @param {string} [first_name='']
 * @param {string} [last_name='']
 * @returns {Object} Subscriber
 */
export default function subscriberFactory( {
	email = '',
	firstName = '',
	lastName = '',
	id = null,
} = {} ) {
	return new Subscriber(
		email,
		firstName,
		lastName,
		id
	);
}

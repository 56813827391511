import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Editor from 'common/Editor';

export default class ReminderTextArea extends PureComponent {
	static propTypes = {
		footer: PropTypes.string.isRequired,
		updateFooter: PropTypes.func.isRequired,
		focusFooter: PropTypes.func.isRequired,
	};

	static defaultProps = {
		footer: '',
	};

	updateFooter = ( content ) => {
		this.props.updateFooter( content );
	};

	editorSetup = ( editor ) => {
		editor.on( 'init', () => editor.getContainer().className += ' tribe-mce' );
	};

	render() {
		const { footer } = this.props;

		return (
			<div className="mceContain">
				<Editor
					id="message"
					content={ footer }
					config={ {
						plugins: 'autolink link image lists print preview noneditable legacyoutput paste',
						toolbar: 'bold italic underline strikethrough | link blockquote currentdate | bullist numlist alignjustify alignleft aligncenter alignright',
						setup: this.editorSetup,
						paste_as_text: true,
					} }
					onChange={ this.updateFooter }
					onFocus={ this.props.focusFooter }
				/>
			</div>
		);
	}
}

import React, { Component } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import styles from './Text.pcss';
import base from 'EmailEditor/app.pcss';
import classNames from 'classnames';

export default class Text extends Component {
	static propTypes = {
		className: PropTypes.string,
		labelClassName: PropTypes.string,
		inputClassName: PropTypes.string,
		value: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		onBlur: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		onFocus: PropTypes.func,
		isActive: PropTypes.bool.isRequired,
		validate: PropTypes.func,
		errorMessage: PropTypes.string,
		placeholder: PropTypes.string,
	};

	static defaultProps = {
		placeholder: '',
		value: '',
		name: '',
		isActive: true,
		errorMessage: 'Invalid',
		onBlur: noop,
		onFocus: noop,
	};

	constructor( props ) {
		super( props );

		this.state = {
			valid: true,
		};
	}

	handleChange = ( e ) => {
		if ( ! this.props.validate ) {
			this.props.onChange( e );
			return;
		}

		this.setState( { valid: this.props.validate( e ) } );

		this.props.onChange( e );
	};

	renderError = () => {
		return (
			! this.state.valid && <span className={ styles.errormessage }>{this.props.errorMessage}</span>
		);
	};

	render() {
		const {
			className,
			labelClassName,
			inputClassName,
			value,
			name,
			isActive,
		} = this.props;

		if ( ! isActive ) {
			return null;
		}

		const classes = classNames( {
			[ inputClassName ]: true,
			[ styles.textinput ]: true,
			[ styles.error ]: ! this.state.valid,
		} );

		return (
			<div className={ classNames( className, styles.inputItem ) }>
				<label htmlFor={ name } className={ classNames( labelClassName, base.boxed__label ) }>
					{ name }
				</label>
				<input
					className={ classes }
					type="text"
					value={ value }
					name={ name }
					id={ name }
					onBlur={ this.props.onBlur }
					onChange={ ( e ) => this.handleChange( e ) }
					onFocus={ this.props.onFocus }
					placeholder={ this.props.placeholder }
				/>
				{this.renderError()}
			</div>
		);
	}
}

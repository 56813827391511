import { takeEvery } from 'redux-saga/effects';
import {
	handleCSVImportSuccess,
	handleCSVImportError,
} from 'AudienceListCreator/ListCreator/sagas/subscriber';
import {
	CSV_IMPORT_SUCCESS,
	CSV_IMPORT_ERROR,
} from 'AudienceListCreator/ListCreator/ducks/addSubscribers';

export default function* root() {
	yield takeEvery( CSV_IMPORT_SUCCESS, handleCSVImportSuccess );
	yield takeEvery( CSV_IMPORT_ERROR, handleCSVImportError );
}

import map from 'lodash/map';
import { fork, all, call, put, take, select, takeEvery } from 'redux-saga/effects';
import { eventChannel, delay } from 'redux-saga';
import { difference, union, noop } from 'lodash';
import { showDeleteModal, showUnsubscribeModal, SUBMIT_DELETE_MODAL, SUBMIT_UNSUBSCRIBE_MODAL, showCopySubscribersModal, SUBMIT_COPY_SUBSCRIBERS_MODAL } from './ducks/modal';
import { syncSelectedSubscribers, syncSubscribers, getSelectedSubscriberIds } from './ducks/subscribers';
import { fetchSearch, fetchListsSuccess, fetchListsError, FETCH_LISTS, hasSearchLists, updateSelectedLists, SELECT_ALL_ACTIVE_LISTS, DESELECT_ALL_ACTIVE_LISTS, DESELECT_ALL_SELECTED_LISTS, getSelected, getSelectedLists } from './ducks/lists';
import { getActiveListIds } from './ducks/activeList';
import { UPDATE_SEARCH_TEXT } from 'Modals/Subscribers/CopySubscribers/ducks/ui';
import * as service from './service';

/**
 *	Interface connecting shortcut card clicks to redux saga
 *
 * @returns Channel
 */
export function showUnsubscribeModalChannel() {
	return eventChannel( emitter => {
		function onClick() {
			emitter( true );
		}

		const unsubscribeButton = document.querySelector( '.subscribers__action.subscribers__action--unsubscribe' );

		if ( ! unsubscribeButton ) {
			return noop;
		}

		unsubscribeButton.addEventListener( 'click', onClick );

		return () => {
			unsubscribeButton.removeEventListener( 'click', onClick );
		};
	}
	);
}

/**
 * Saga receiving channel emits and updating store
 *
 * @export
 */
export function* showUnsubscribeModalListener() {
	const channel = yield call( showUnsubscribeModalChannel );
	while ( true ) {
		yield take( channel );
		yield put( showUnsubscribeModal() );
	}
}

/**
 *	Interface connecting shortcut card clicks to redux saga
 *
 * @returns Channel
 */
export function showDeleteModalChannel() {
	return eventChannel( emitter => {
		function onClick() {
			emitter( true );
		}

		const deleteButton = document.querySelector( '.subscribers__action.subscribers__action--delete' );

		if ( ! deleteButton ) {
			return noop;
		}

		deleteButton.addEventListener( 'click', onClick );

		return () => {
			deleteButton.removeEventListener( 'click', onClick );
		};
	}
	);
}

/**
 * Saga receiving channel emits and updating store
 *
 * @export
 */
export function* showDeleteModalListener() {
	const channel = yield call( showDeleteModalChannel );
	while ( true ) {
		yield take( channel );
		yield put( showDeleteModal() );
	}
}

/**
 *	Interface connecting shortcut card clicks to redux saga
 *
 * @returns Channel
 */
export function showCopySubscriberModalChannel() {
	return eventChannel( emitter => {
		function onClick() {
			emitter( true );
		}

		const copyButton = document.querySelector( '.subscribers__action.subscribers__action--copy' );

		if ( ! copyButton ) {
			return noop;
		}

		copyButton.addEventListener( 'click', onClick );

		return () => {
			copyButton.removeEventListener( 'click', onClick );
		};
	}
	);
}

/**
 * Saga receiving channel emits and updating store
 *
 * @export
 */
export function* showCopySubscriberModalListener() {
	const channel = yield call( showCopySubscriberModalChannel );
	while ( true ) {
		yield take( channel );
		yield put( showCopySubscribersModal() );
	}
}

/**
 *	Interface connecting shortcut card clicks to redux saga
 *
 * @returns Channel
 */
export function formChangeChannel() {
	return eventChannel( emitter => {
		function onChange() {
			emitter( new FormData( this ) );
		}

		const form = document.querySelector( '#subscriber-form' );

		if ( ! form ) {
			return noop;
		}

		form.addEventListener( 'change', onChange );

		return () => {
			form.removeEventListener( 'change', onChange );
		};
	}
	);
}

export function* deriveSelectedSubscribersListener() {
	const channel = yield call( formChangeChannel );
	while ( true ) {
		const formData = yield take( channel );
		const ids = formData.getAll( 'subscribers[]' ).map( Number );
		yield put( syncSelectedSubscribers( ids ) );
	}
}

export function* syncSubscriberData() {
	try {
		const nodes = Array.from( document.querySelectorAll( '.subscribers__table-row template' ) );
		const subscribers = nodes.map( node => JSON.parse( node.innerHTML ) );
		yield put( syncSubscribers( subscribers ) );
	} catch ( error ) {
		console.error( error );
	}
}

export function* handleSubscriberDeletion() {
	while ( true ) {
		yield take( SUBMIT_DELETE_MODAL );
		const subscribers = yield select( getSelectedSubscriberIds );
		yield call( service.deleteSubscribers, { subscribers } );
		yield call( [ window.location, 'reload' ] );
	}
}

export function* handleSubscriberUnsubscribe() {
	while ( true ) {
		yield take( SUBMIT_UNSUBSCRIBE_MODAL );
		const subscribers = yield select( getSelectedSubscriberIds );
		yield call( service.unsubscriberSubscribers, { subscribers } );
		yield call( [ window.location, 'reload' ] );
	}
}
/**
 * Saga to handle list fetches
 *
 * @export
 * @param {Object} action
 */
export function* fetchListSaga( action ) {
	try {
		const lists = yield call(
			service.fetchLists,
			action.payload.params
		);

		yield put(
			fetchListsSuccess( {
				...action.payload,
				lists,
			} )
		);
	} catch ( error ) {
		yield put( fetchListsError( { error } ) );
	}
}

/**
 * Input change handler
 * Handles debouncing and only fetches when no events
 *
 * @export
 * @param {Object} action redux action
 */
export function* handleSearchInputChange( action ) {
	yield delay( 500 ); // Debounce
	const hasLists = yield select( hasSearchLists );
	if ( ! hasLists && action.payload.searchText ) {
		yield put(
			fetchSearch( { search: action.payload.searchText, limit: 50 } )
		);
	}
}

export function* handleSelectAllEvents( action ) {
	const active = yield select( getActiveListIds );
	const selected = yield select( getSelected );
	let lists;

	switch ( action.type ) {
		case SELECT_ALL_ACTIVE_LISTS :
			lists = union( selected, active );
			break;

		case DESELECT_ALL_ACTIVE_LISTS:
			lists = difference( selected, active );
			break;

		case DESELECT_ALL_SELECTED_LISTS:
		default:
			lists = [];
			break;
	}

	yield put( updateSelectedLists( lists ) );
}

export function* onCopySubscriberModalSubmit() {
	const selectedLists = yield select( getSelectedLists );
	const subscribers = yield select( getSelectedSubscriberIds );
	yield all(
		map(
			selectedLists,
			( list ) => call( service.copySubscribersToList, list.id, { subscribers } )
		)
	);
	yield call( [ window.location, 'reload' ] );
}

export function* listeners() {
	yield takeEvery( [ UPDATE_SEARCH_TEXT ], handleSearchInputChange );
	yield takeEvery( [ SELECT_ALL_ACTIVE_LISTS, DESELECT_ALL_ACTIVE_LISTS, DESELECT_ALL_SELECTED_LISTS ], handleSelectAllEvents );
	yield takeEvery( [ SUBMIT_COPY_SUBSCRIBERS_MODAL ], onCopySubscriberModalSubmit );
	yield call( fetchListSaga, { payload: { params: {} } } );
	yield takeEvery( [ FETCH_LISTS ], fetchListSaga );
}

export const childSagas = [
	syncSubscriberData,
	showUnsubscribeModalListener,
	showDeleteModalListener,
	deriveSelectedSubscribersListener,
	handleSubscriberDeletion,
	handleSubscriberUnsubscribe,
	showCopySubscriberModalListener,
	listeners,
];

export function* mainSaga( childrenSagas ) {
	yield all( [
		...map( childrenSagas, childSaga => ( fork( childSaga ) ) ),
	] );
}

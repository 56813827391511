import { createSelector } from 'reselect';
import { get, includes } from 'lodash';
import { CREATE_LIST_ERROR } from './index';

const initialState = {
	hasReachedUserUploadLimit: false,
};

export default function errors( state = initialState, action ) {
	switch ( action.type ) {
		case CREATE_LIST_ERROR:
			return {
				...state,
				hasReachedUserUploadLimit: includes(
					get( action, 'payload.response.data.message' ), 'has reached subscriber limit'
				),
			};

		default:
			return state;
	}
}

const getSlice = state => state.errors;
export const hasReachedUserUploadLimit = createSelector( getSlice, slice => slice.hasReachedUserUploadLimit );

import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';

export const UPDATE_SEARCH_TEXT = 'modals/copySubscribers/UPDATE_SEARCH_TEXT';
export const OPEN_SELECTED = 'modals/copySubscribers/OPEN_SELECTED';
export const CLOSE_SELECTED = 'modals/copySubscribers/CLOSE_SELECTED';

export const updateSearchText = createAction( UPDATE_SEARCH_TEXT, searchText => ( { searchText } ) );
export const openSelected = createAction( OPEN_SELECTED, () => ( { selectedOpen: true } ) );
export const closeSelected = createAction( CLOSE_SELECTED, () => ( { selectedOpen: false } ) );

const initialState = {
	searchText: '',
	selectedOpen: false,
};

export default function uiReducer( state = initialState, action ) {
	switch ( action.type ) {
		case UPDATE_SEARCH_TEXT:
		case OPEN_SELECTED:
		case CLOSE_SELECTED:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}

export const getUISlice = state => state.ui;
export const getSearchText = createSelector( getUISlice, ui => ui.searchText );
export const hasSearchText = createSelector( getSearchText, searchText => !! searchText );
export const isSelectedOpen = createSelector( getUISlice, ui => ui.selectedOpen );

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './BlockContainer.pcss';
import classNames from 'classnames';

export default class BlockContainer extends Component {
	static propTypes = {
		outerClass: PropTypes.string,
		children: PropTypes.any,
	};

	static defaultProps = {
		outerClass: '',
		children: null,
	};

	constructor( props ) {
		super( props );
		this.wrapper = React.createRef();
	}

	state = {
		focused: false,
	};

	setFocusIn = () => {
		this.setState( { focused: true } );
	};

	setFocusOut = () => {
		this.setState( { focused: false } );
	};

	componentDidMount() {
		this.wrapper.current.addEventListener( 'focusin', this.setFocusIn );
		this.wrapper.current.addEventListener( 'focusout', this.setFocusOut );
	}

	componentWillUnmount() {
		this.wrapper.current.removeEventListener( 'focusin', this.setFocusIn );
		this.wrapper.current.removeEventListener( 'focusout', this.setFocusOut );
	}

	render() {
		const { focused } = this.state;
		const { outerClass, children } = this.props;

		const outerClasses = classNames( {
			[ outerClass ]: true,
			[ styles.focused ]: focused,
		} );

		return (
			<div className={ outerClasses } ref={ this.wrapper }>
				<div className={ styles.boxed }>
					<div className="row--inner">
						<div className={ styles.contain }>
							{children}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

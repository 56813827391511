import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { keyBy } from 'lodash';

export const SYNC_SELECTED_SUBSCRIBERS = 'audiencelist/lists/SYNC_SELECTED_SUBSCRIBERS';
export const SYNC_SUBSCRIBERS = 'audiencelist/lists/SYNC_SUBSCRIBERS';

export const syncSelectedSubscribers = createAction( SYNC_SELECTED_SUBSCRIBERS );
export const syncSubscribers = createAction( SYNC_SUBSCRIBERS );

export const INITIAL_STATE = {
	selected: [],
	subscribers: [],
};

export default function subscibersReducer( state = INITIAL_STATE, action ) {
	switch ( action.type ) {
		case SYNC_SELECTED_SUBSCRIBERS:
			return {
				...state,
				selected: action.payload,
			};
		case SYNC_SUBSCRIBERS:
			return {
				...state,
				subscribers: keyBy( action.payload, 'id' ),
			};

		default:
			return state;
	}
}

const getSubscribersSlice = state => state.subscribers;
export const getSubscribers = createSelector( getSubscribersSlice, ( { subscribers } ) => subscribers );
export const getSelectedSubscriberIds = createSelector( getSubscribersSlice, subscribers => subscribers.selected );
export const getSelectedSubscribers = createSelector(
	[ getSelectedSubscriberIds, getSubscribers ],
	( ids, subscribers ) => ids.map( id => subscribers[ id ] )
);

export const getSubscribersByList = createSelector(
	[ getSelectedSubscribers ],
	subscribers => subscribers.reduce( ( acc, subscriber ) => {
		subscriber.lists.forEach( list => {
			if ( acc[ list.id ] ) {
				acc[ list.id ].push( subscriber.id );
			} else {
				acc[ list.id ] = [ subscriber.id ];
			}
		} );
		return acc;
	}, {} )
);

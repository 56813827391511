import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { every, first } from 'lodash';
import { LIST_EDITOR_SETTINGS, CUSTOM_LIST } from 'config/settings';
import { hasValidActiveSubscribers } from './addSubscribers';

const PREFIX = 'audiences/listeditor';

export const UPDATE_TITLE = `${ PREFIX }/UPDATE_TITLE`;
export const UPDATE_FOOTER = `${ PREFIX }/UPDATE_FOOTER`;
export const UPDATE_COLOR = `${ PREFIX }/UPDATE_COLOR`;
export const TOGGLE_PERMISSION = `${ PREFIX }/TOGGLE_PERMISSION`;
export const CREATE_LIST = `${ PREFIX }/CREATE_LIST`;
export const CREATE_LIST_SUCCESS = `${ PREFIX }/CREATE_LIST_SUCCESS`;
export const CREATE_LIST_ERROR = `${ PREFIX }/CREATE_LIST_ERROR`;

export const updateTitle = createAction( UPDATE_TITLE, title => ( { title } ) );
export const updateFooter = createAction( UPDATE_FOOTER, footer => ( { footer } ) );
export const updateColor = createAction( UPDATE_COLOR, color => ( { color } ) );
export const togglePermission = createAction( TOGGLE_PERMISSION );
export const createList = createAction( CREATE_LIST );
export const createListSuccess = createAction( CREATE_LIST_SUCCESS );
export const createListError = createAction( CREATE_LIST_ERROR );

const initialState = {
	title: CUSTOM_LIST.title || '',
	footer: CUSTOM_LIST.footer || '',
	color: CUSTOM_LIST.color || first( LIST_EDITOR_SETTINGS.colors ),
	permission: false,
	isSubmitting: false,
	id: CUSTOM_LIST.id || 0,
};

export default function form( state = initialState, action ) {
	switch ( action.type ) {
		case UPDATE_TITLE:
		case UPDATE_FOOTER:
		case UPDATE_COLOR:
			return {
				...state,
				...action.payload,
			};

		case TOGGLE_PERMISSION:
			return {
				...state,
				permission: ! state.permission,
			};

		case CREATE_LIST:
			return {
				...state,
				isSubmitting: true,
			};

		case CREATE_LIST_SUCCESS:
		case CREATE_LIST_ERROR:
			return {
				...state,
				isSubmitting: false,
			};

		default:
			return state;
	}
}

export const getFormSlice = state => state.form;
export const getTitle = createSelector( getFormSlice, slice => slice.title );
export const getFooter = createSelector( getFormSlice, slice => slice.footer );
export const getColor = createSelector( getFormSlice, slice => slice.color );
export const getPermission = createSelector( getFormSlice, slice => slice.permission );
export const isSubmitting = createSelector( getFormSlice, slice => slice.isSubmitting );

export const getHeaderTitle = createSelector( getTitle, title => title || 'New Custom List' );
export const hasValidFormValues = createSelector(
	[ getTitle, getFooter, getColor, getPermission, hasValidActiveSubscribers ],
	( ...selectors ) => every( selectors, value => !! value )
);

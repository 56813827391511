import React from 'react';
import PropTypes from 'prop-types';
import styles from './modal.pcss';

const ModalHeader = ( {
	title,
	hideModal,
} ) => {
	return (
		<header className={ styles.modal__header }>
			<div className={ styles.inner }>
				<div className={ styles.flexedheader }>
					<div className={ styles.title }>
						<a href="/" className="site-logo">
							<span className="a11y-visual-hide">Promoter</span>
						</a>
						<h1 className={ styles.modal__headertitle }>
							{title}
						</h1>
					</div>
					<button className={ styles.iconclose } aria-label="Close modal" type="button" onClick={ hideModal }>
						<span className={ styles.iconcloseinner }></span>
					</button>
				</div>
			</div>
		</header>
	);
};

ModalHeader.propTypes = {
	title: PropTypes.string,
	hideModal: PropTypes.func,
};

export default ModalHeader;

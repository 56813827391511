import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { includes, difference, map } from 'lodash';
import { getSearchText } from 'Modals/Subscribers/CopySubscribers/ducks/ui';

export const ALL = 'all';
export const SEARCH = 'search';
export const SELECTED = 'selected';

export const FETCH_LISTS = 'subscriberApp/copySubscribers/FETCH_LISTS';
export const FETCH_LISTS_SUCCESS = 'subscriberApp/copySubscribers/FETCH_LISTS_SUCCESS';
export const FETCH_LISTS_ERROR = 'subscriberApp/copySubscribers/FETCH_LISTS_ERROR';
export const TOGGLE_LIST_SELECT = 'subscriberApp/copySubscribers/TOGGLE_LIST_SELECT';
export const SELECT_ALL_ACTIVE_LISTS = 'subscriberApp/copySubscribers/SELECT_ALL_ACTIVE_LISTS';
export const DESELECT_ALL_ACTIVE_LISTS = 'subscriberApp/copySubscribers/DESELECT_ALL_ACTIVE_LISTS';
export const DESELECT_ALL_SELECTED_LISTS = 'subscriberApp/copySubscribers/DESELECT_ALL_SELECTED_LISTS';
export const UPDATE_SELECTED_LISTS = 'subscriberApp/copySubscribers/UPDATE_SELECTED_LISTS';

export const fetchLists = createAction( FETCH_LISTS );
export const fetchListsSuccess = createAction( FETCH_LISTS_SUCCESS );
export const fetchListsError = createAction( FETCH_LISTS_ERROR );
export const toggleListSelect = createAction( TOGGLE_LIST_SELECT );
export const selectAllActiveLists = createAction( SELECT_ALL_ACTIVE_LISTS );
export const deselectAllActiveLists = createAction( DESELECT_ALL_ACTIVE_LISTS );
export const deselectAllSelectedLists = createAction( DESELECT_ALL_SELECTED_LISTS );
export const updateSelectedLists = createAction( UPDATE_SELECTED_LISTS );

export const fetchSearch = createAction( FETCH_LISTS, params => ( {
	fetchType: SEARCH,
	params,
} ) );

const initialState = {
	isFetching: false,
	[ ALL ]: {},
	[ SEARCH ]: {},
	[ SELECTED ]: [],
};

const toId = list => list.id;
const mapListsById = lists => lists.reduce( ( acc, list ) => {
	acc[ list.id ] = list;
	return acc;
}, {} );

export function listsFetchSuccessReducer( state, action ) {
	switch ( action.payload.fetchType ) {
		case SEARCH:
			return {
				[ SEARCH ]: {
					...state[ SEARCH ],
					[ action.payload.params.search ]: action.payload.lists.map( toId ),
				},
			};

		default:
			return state;
	}
}

export function eventSelectionReducer( state, action ) {
	const selected = state[ SELECTED ];
	const hasList = includes( selected, action.payload );

	const lists = hasList
		? difference( selected, [ action.payload ] )
		: [ ...selected, action.payload ];

	return {
		[ SELECTED ]: lists,
	};
}

export default function listsReducer( state = initialState, action ) {
	switch ( action.type ) {
		case FETCH_LISTS:
			return {
				...state,
				isFetching: true,
			};

		case FETCH_LISTS_SUCCESS:
			return {
				...state,
				...listsFetchSuccessReducer( state, action ),
				[ ALL ]: {
					...state[ ALL ],
					...mapListsById( action.payload.lists ),
				},
				isFetching: false,
			};

		case TOGGLE_LIST_SELECT:
			return {
				...state,
				...eventSelectionReducer( state, action ),
			};

		case UPDATE_SELECTED_LISTS:
			return {
				...state,
				[ SELECTED ]: action.payload,
			};

		case FETCH_LISTS_ERROR:
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

export const getListsSlice = state => state.lists;
export const isFetching = createSelector( getListsSlice, lists => lists.isFetching );
export const getAll = createSelector( getListsSlice, lists => lists[ ALL ] );
export const getSearched = createSelector( getListsSlice, lists => lists[ SEARCH ] );
export const getSelected = createSelector( getListsSlice, lists => lists[ SELECTED ] );

export const getAllLists = createSelector( getAll, all => map( all, list => list ) );
export const hasSearchLists = createSelector( [ getSearched, getSearchText ], ( lists, searchText ) => lists.hasOwnProperty( searchText ) );
export const getSearchedIds = createSelector( [ getSearched, getSearchText ], ( lists, searchText ) => lists[ searchText ] || [] );
export const getSearchedLists = createSelector( [ getAll, getSearchedIds ], ( all, searchedIds ) => searchedIds.map( id => all[ id ] ) );
export const getSelectedLists = createSelector( [ getAll, getSelected ], ( all, selectedIds ) => selectedIds.map( id => all[ id ] ) );

import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { HIDE_MODAL } from 'common/Modal/ducks';
import Unsubscribe from 'Modals/Subscribers/Unsubscribe';
import Delete from 'Modals/Subscribers/Delete';
import CopySubscribers from 'Modals/Subscribers/CopySubscribers';

export const MODALS_FOR_PAGE = {
	Unsubscribe,
	Delete,
	CopySubscribers,
};

const [ UNSUBSCRIBE_MODAL, DELETE_MODAL, COPY_SUBSCRIBERS_MODAL ] = Object.keys( MODALS_FOR_PAGE );

export const SHOW_UNSUBSCRIBE_MODAL = 'audiencelist/modals/SHOW_UNSUBSCRIBE_MODAL';
export const SUBMIT_UNSUBSCRIBE_MODAL = 'audiencelist/modals/SUBMIT_UNSUBSCRIBE_MODAL';

export const showUnsubscribeModal = createAction( SHOW_UNSUBSCRIBE_MODAL );
export const submitUnsubscribeModal = createAction( SUBMIT_UNSUBSCRIBE_MODAL );

export const SHOW_DELETE_MODAL = 'audiencelist/modals/SHOW_DELETE_MODAL';
export const SUBMIT_DELETE_MODAL = 'audiencelist/modals/SUBMIT_DELETE_MODAL';

export const showDeleteModal = createAction( SHOW_DELETE_MODAL );
export const submitDeleteModal = createAction( SUBMIT_DELETE_MODAL );

export const SHOW_COPY_SUBSCRIBERS_MODAL = 'audiencelist/modals/SHOW_COPY_SUBSCRIBERS_MODAL';
export const SUBMIT_COPY_SUBSCRIBERS_MODAL = 'audiencelist/modals/SUBMIT_COPY_SUBSCRIBERS_MODAL';

export const showCopySubscribersModal = createAction( SHOW_COPY_SUBSCRIBERS_MODAL );
export const submitCopySubscribersModal = createAction( SUBMIT_COPY_SUBSCRIBERS_MODAL );

export const INITIAL_STATE = {
	content: '',
};

export default function modalReducer( state = INITIAL_STATE, action ) {
	switch ( action.type ) {
		case SHOW_UNSUBSCRIBE_MODAL:
			return {
				...state,
				content: UNSUBSCRIBE_MODAL,
			};

		case SHOW_DELETE_MODAL:
			return {
				...state,
				content: DELETE_MODAL,
			};

		case SHOW_COPY_SUBSCRIBERS_MODAL:
			return {
				...state,
				content: COPY_SUBSCRIBERS_MODAL,
			};

		case HIDE_MODAL:
			return {
				...state,
				content: '',
			};

		default:
			return state;
	}
}

const getModalSlice = state => state.modal;
export const getModalContent = createSelector( getModalSlice, modal => modal.content );
export const getModalSubmitHandler = createSelector( getModalContent, modal => {
	switch ( modal ) {
		case UNSUBSCRIBE_MODAL:
			return submitUnsubscribeModal;
		case DELETE_MODAL:
			return submitDeleteModal;
		case COPY_SUBSCRIBERS_MODAL:
			return submitCopySubscribersModal;
		default:
			return console.error;
	}
} );


import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './BlockHeader.pcss';

const BlockHeader = ( { title, description, className } ) => (
	<div className={ classNames( styles.info, className ) }>
		<div className={ styles.section }>
			{title}
		</div>
		<div
			className={ styles.description }
			dangerouslySetInnerHTML={ { __html: description } }
		/>
	</div>
);

BlockHeader.propTypes = {
	title: PropTypes.string,
	description: PropTypes.oneOfType( [
		PropTypes.string,
		PropTypes.node,
	] ),
	className: PropTypes.string,
};

BlockHeader.defaultProps = {
	title: '',
	description: '',
	className: '',
};

export default BlockHeader;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'common/Button';
import ModalContent from 'common/Modal/components/ModalContent';
import Label from 'common/Label';
import Text from 'common/Text';
import ReminderTextArea from 'AudienceListCreator/ListCreator/ListReminder/components/ReminderTextArea';
import Colors from 'AudienceListCreator/ListCreator/ListTitle/components/Colors';
import styles from 'AudienceListCreator/ListCreator/ListTitle/components/index.pcss';
import contentStyles from './content.pcss';

const EditListSettings = ( {
	hideModal,
	onSubmit,
	listTitle,
	footer,
	color,
	title,
	updateColor,
	updateFooter,
	updateTitle,
	canSubmit,
} ) => {
	return (
		<ModalContent
			title={ `${ listTitle } Settings` }
			hideModal={ hideModal }
		>
			<section className={ contentStyles.section }>
				<h1 className={ contentStyles.title }>List Title</h1>
				<p className={ contentStyles.subtitle }>Your subscribers won't see this name, it's for your use only.</p>
				<Text
					className={ classNames( styles.inputWrap, contentStyles.subtitle ) }
					inputClassName={ styles.input }
					labelClassName="a11y-visual-hide"
					value={ title }
					name="title"
					onChange={ updateTitle }
				/>
				<Label>
					Set Custom Color
				</Label>
				<Colors activeColor={ color } updateColor={ updateColor } />
			</section>

			<section>
				<h1 className={ contentStyles.title }>Remind subscribers why they are on this list</h1>
				<p className={ contentStyles.subtitle }>Tell users where they originally subscribed. This will show in the footer of emails sent.</p>
				<ReminderTextArea footer={ footer } updateFooter={ updateFooter } />
			</section>

			<footer className={ contentStyles.footer }>
				<Button
					label="Save Updates"
					name="create"
					onClick={ onSubmit }
					type="blue"
					isActive={ canSubmit }
				/>
				<Button
					label="Cancel"
					name="cancel"
					onClick={ hideModal }
					type="tertiary"
				/>
			</footer>
		</ModalContent>
	);
};

EditListSettings.propTypes = {
	hideModal: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	listTitle: PropTypes.string.isRequired,
	footer: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	updateColor: PropTypes.func.isRequired,
	updateFooter: PropTypes.func.isRequired,
	updateTitle: PropTypes.func.isRequired,
	canSubmit: PropTypes.bool.isRequired,
};

export default EditListSettings;

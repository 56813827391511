import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { uniqBy, filter, map, pick } from 'lodash';
import { pipe } from 'lodash/fp';
import { isEmail } from 'react-multi-email';
import subscriberFactory from '../factories/subscriber';
import {
	CSV_SUBSCRIBERS,
	COPY_PASTE_SUBSCRIBERS,
	INDIVIDUAL_SUBSCRIBERS,
} from '../constants/subscriber';

const PREFIX = 'audiences/listeditor';

export const ADD_INDIVIDUAL_SUBSCRIBER = `${ PREFIX }/ADD_INDIVIDUAL_SUBSCRIBER`;
export const REMOVE_INDIVIDUAL_SUBSCRIBER = `${ PREFIX }/REMOVE_INDIVIDUAL_SUBSCRIBER`;
export const UPDATE_INDIVIDUAL_SUBSCRIBER = `${ PREFIX }/UPDATE_INDIVIDUAL_SUBSCRIBER`;
export const UPDATE_COPY_PASTE_SUBSCRIBERS = `${ PREFIX }/UPDATE_COPY_PASTE_SUBSCRIBERS`;
export const UPDATE_CSV_IMPORT_SUBSCRIBERS = `${ PREFIX }/UPDATE_CSV_IMPORT_SUBSCRIBERS`;
export const CSV_IMPORT_SUCCESS = `${ PREFIX }/CSV_IMPORT_SUCCESS`;
export const CSV_IMPORT_ERROR = `${ PREFIX }/CSV_IMPORT_ERROR`;
export const SET_ACTIVE_SUBSCRIBER_TYPE = `${ PREFIX }/SET_ACTIVE_SUBSCRIBER_TYPE`;

export const addIndividualSubscriber = createAction( ADD_INDIVIDUAL_SUBSCRIBER );
export const removeIndividualSubscriber = createAction( REMOVE_INDIVIDUAL_SUBSCRIBER, index => index );
export const updateIndividualSubscriber = createAction( UPDATE_INDIVIDUAL_SUBSCRIBER, ( index, subscriber ) => ( { index, subscriber } ) );
export const updateCopyPasteSubscribers = createAction( UPDATE_COPY_PASTE_SUBSCRIBERS );
export const updateCSVImportSubscibers = createAction( UPDATE_CSV_IMPORT_SUBSCRIBERS );
export const csvImportSuccess = createAction( CSV_IMPORT_SUCCESS );
export const csvImportError = createAction( CSV_IMPORT_ERROR );

export const setActiveSubscriberType = createAction( SET_ACTIVE_SUBSCRIBER_TYPE, active => ( { active } ) );

const initialState = {
	active: null,
	[ CSV_SUBSCRIBERS ]: [],
	[ COPY_PASTE_SUBSCRIBERS ]: [],
	[ INDIVIDUAL_SUBSCRIBERS ]: [ subscriberFactory() ],
};

function individualSubscribersReducer( state = {}, action ) {
	switch ( action.type ) {
		case ADD_INDIVIDUAL_SUBSCRIBER:
			return {
				...state,
				[ INDIVIDUAL_SUBSCRIBERS ]: [
					...state[ INDIVIDUAL_SUBSCRIBERS ],
					subscriberFactory(),
				],
			};

		case REMOVE_INDIVIDUAL_SUBSCRIBER:
			return {
				...state,
				[ INDIVIDUAL_SUBSCRIBERS ]: state[ INDIVIDUAL_SUBSCRIBERS ].filter( ( _, i ) => i !== action.payload ),
			};

		case UPDATE_INDIVIDUAL_SUBSCRIBER:
			return {
				...state,
				[ INDIVIDUAL_SUBSCRIBERS ]: state[ INDIVIDUAL_SUBSCRIBERS ].reduce( ( acc, subscriber, i ) => {
					if ( i === action.payload.index ) {
						acc.push(
							subscriberFactory( action.payload.subscriber )
						);
					} else {
						acc.push( subscriber );
					}
					return acc;
				}, [] ),
			};

		default:
			return state;
	}
}

export default function subscribers( state = initialState, action ) {
	switch ( action.type ) {
		case ADD_INDIVIDUAL_SUBSCRIBER:
		case UPDATE_INDIVIDUAL_SUBSCRIBER:
		case REMOVE_INDIVIDUAL_SUBSCRIBER:
			return individualSubscribersReducer( state, action );

		case UPDATE_COPY_PASTE_SUBSCRIBERS:
			return {
				...state,
				[ COPY_PASTE_SUBSCRIBERS ]: action.payload.map( email => subscriberFactory( { email } ) ),
			};

		case UPDATE_CSV_IMPORT_SUBSCRIBERS:
			return {
				...state,
				[ CSV_SUBSCRIBERS ]: action.payload,
			};

		case SET_ACTIVE_SUBSCRIBER_TYPE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
}

export const getSubscriberSlice = state => state.addSubscribers;
export const getActiveSubscriberTab = createSelector( getSubscriberSlice, subs => subs.active );
export const isCSVTabActive = createSelector( getActiveSubscriberTab, tab => tab === CSV_SUBSCRIBERS );
export const isCopyPasteTabActive = createSelector( getActiveSubscriberTab, tab => tab === COPY_PASTE_SUBSCRIBERS );
export const isIndividualTabActive = createSelector( getActiveSubscriberTab, tab => tab === INDIVIDUAL_SUBSCRIBERS );

export const getCSVSubscribers = createSelector( getSubscriberSlice, subs => subs[ CSV_SUBSCRIBERS ] );
export const getCopyPasteSubscribers = createSelector( getSubscriberSlice, subs => subs[ COPY_PASTE_SUBSCRIBERS ] );
export const getIndividualSubscribers = createSelector( getSubscriberSlice, subs => subs[ INDIVIDUAL_SUBSCRIBERS ] );

const getValidSubscribers = pipe(
	subs => filter( subs, ( { email } ) => !! email && isEmail( email ) ),
	subs => uniqBy( subs, 'email' ),
	subs => map( subs, sub => pick( sub, [ 'first_name', 'last_name', 'email' ] ) )
);

export const getActiveSubscribers = createSelector( [ getSubscriberSlice, getActiveSubscriberTab ], ( subs, active ) => subs[ active ] );
export const getValidActiveSubscribers = createSelector( getActiveSubscribers, getValidSubscribers );
export const hasValidActiveSubscribers = createSelector( getValidActiveSubscribers, subs => !! ( subs && subs.length ) );

export const showCSVFormatError = createSelector(
	[ getCSVSubscribers, hasValidActiveSubscribers ],
	( subs, hasValidSubs ) => !! subs.length && ! hasValidSubs
);

import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import ReactCSVReader from 'react-csv-reader';
import Button from 'common/Button';
import MockInput from 'common/MockInput';
import Label from 'common/Label';
import styles from './CSVReader.pcss';

export default class CSVReader extends PureComponent {
	static propTypes = {
		onFileLoaded: PropTypes.func.isRequired,
		onError: PropTypes.func.isRequired,
		label: PropTypes.string,
	}

	state = {
		filename: '',
	}

	container = createRef();

	handleFileLoaded = ( data, filename ) => {
		this.props.onFileLoaded( data );
		this.setState( { filename } );
	}

	handleError = ( error ) => {
		this.props.onError( error );
	}

	handleRemove = () => {
		this.setState( { filename: '' } );
		this.props.onFileLoaded( [] );
		this.container.current.querySelector( 'input' ).value = null;
	}

	handleAddClick = () => {
		try {
			this.container.current.querySelector( 'input' ).click();
		} catch ( error ) {
		}
	}

	render() {
		return (
			<div ref={ this.container } className={ styles.CSVReader }>
				<Label className={ styles.label } htmlFor="csvreader">
					<span>
						{this.props.label}
					</span>
				</Label>
				<div className={ styles.reader }>
					<ReactCSVReader
						cssClass="csv-reader-input"
						onFileLoaded={ this.handleFileLoaded }
						onError={ this.handleError }
						cssInputClass="a11y-visual-hide"
						inputId="csvreader"
					/>
					{
						!! this.state.filename && (
							<MockInput>
								{ this.state.filename }
							</MockInput>
						)
					}
					{
						! this.state.filename && (
							<Button type="blue-transparent" label="Import File" onClick={ this.handleAddClick } />
						)
					}
					{
						!! this.state.filename && (
							<Button type="delete" onClick={ this.handleRemove } />
						)
					}
				</div>
			</div>
		);
	}
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trim } from 'lodash';
import classNames from 'classnames';
import Button from 'common/Button';
import ModalContent from 'common/Modal/components/ModalContent';
import { IMAGES_URL } from 'config/settings';
import SelectMod from 'apps/common/Select';
import modalStyles from 'common/Modal/components/modal.pcss';
import styles from './content.pcss';

export default class Unsubscribe extends Component {
	static propTypes = {
		subscribers: PropTypes.arrayOf( PropTypes.object ),
		hideModal: PropTypes.func,
		onSubmit: PropTypes.func,
		modalTitleSuffix: PropTypes.string,
		modalContentSuffix: PropTypes.string,
	};

	static defaultProps = {
		modalTitleSuffix: '',
		modalContentSuffix: '',
	}

	state = {
		validConfirm: false,
	};

	confirmOptions = [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' },
	];

	handleConfirmValidation = ( e ) => this.setState( { validConfirm: e } );

	get subscriberCount() {
		return this.props.subscribers.length;
	}

	get subscriberText() {
		return this.subscriberCount > 1 ? 'Subscribers' : 'Subscriber';
	}

	render() {
		return (
			<ModalContent
				title={ `Unsubscribe ${ this.subscriberCount } ${ this.subscriberText } ${ this.props.modalTitleSuffix }` }
				hideModal={ this.props.hideModal }
			>
				<div className={ modalStyles.flexedcontent }>
					<div className={ classNames( modalStyles.modal__columnleft, modalStyles.modal__columnleftsend ) }>
						<img
							className={ classNames( modalStyles.modal__image, styles.img ) }
							src={ `${ IMAGES_URL }/unsub-illy.png` }
							alt=""
						/>
					</div>
					<div className={ modalStyles.modal__columnright }>
						<h2 className={ modalStyles.modal__contenttitle }>You're about to unsubscribe {this.subscriberCount} {this.props.modalContentSuffix}</h2>

						<div className={ modalStyles.modal__details }>
							{
								this.props.subscribers.slice( 0, 5 ).map( ( { email, first_name, last_name } ) => {
									const name = ( first_name || last_name )
										? `- ${ first_name } ${ last_name }`
										: '';

									return (
										<div className={ modalStyles.modal__detailsitem }>
											{ email } { trim( name ) }
										</div>
									);
								} )
							}
						</div>
						<div className={ modalStyles.modal__select }>
							<div className={ modalStyles.modal__selectlabel }>Unsubscribe these subscribers</div>
							<SelectMod name="confirmCheck" options={ this.confirmOptions } onChange={ this.handleConfirmValidation } isActive={ true } value={ this.state.validConfirm } />
						</div>
						<div className={ modalStyles.btngroup }>
							<Button type="red" label="Unsubscribe Now" name="delete" onClick={ this.props.onSubmit } isActive={ this.state.validConfirm } />
							<Button type="tertiary" label="Cancel" name="cancel" onClick={ this.props.hideModal } />
						</div>
					</div>
				</div>

			</ModalContent>
		);
	}
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Toggle.pcss';

class Toggle extends Component {
	static propTypes = {
		data: PropTypes.number,
		default: PropTypes.number,
		depth: PropTypes.number,
		description: PropTypes.string,
		indexMap: PropTypes.array,
		parentMap: PropTypes.array,
		label: PropTypes.string,
		name: PropTypes.string,
		options: PropTypes.array,
		stylized: PropTypes.bool,
		onChange: PropTypes.func,
		fieldClassName: PropTypes.string,
		labelClassName: PropTypes.string,
		wrapperClassName: PropTypes.string,
	};

	static defaultProps = {
		data: 0,
		default: 0,
		depth: 0,
		description: '',
		indexMap: [],
		parentMap: [],
		label: '',
		name: '',
		options: [],
		stylized: true,
	};

	state = {
		value: this.props.data,
	};

	handleChange = ( e ) => {
		const target = e.currentTarget;
		const value = parseInt( target.value, 10 ) === 0 ? 1 : 0;
		this.setState( { value } );

		this.props.onChange( this.props.name, Boolean( value ) );
	};

	renderOptions() {
		const toggleLabelClasses = classNames( [ 'toggle', styles.toggleLabel ] );

		return (
			<label
				htmlFor={ `promoter-${ this.props.name }` }
				className={ toggleLabelClasses }
			>
				<input
					type="checkbox"
					value={ this.state.value }
					tabIndex={ 0 }
					name={ `promoter-${ this.props.name }` }
					id={ `promoter-${ this.props.name }` }
					className={ styles.toggleInput }
					onChange={ this.handleChange }
					checked={ this.state.value === 1 }
					data-field-type="toggle"
					data-field="checkbox"
				/>
				<div
					className={ styles.toggleSlider }
				/>
				<span />
			</label>
		);
	}

	render() {
		const {
			fieldClassName,
			labelClassName,
			wrapperClassName,
		} = this.props;

		const labelClasses = classNames( [
			labelClassName,
			'promoter__toggle-label',
			styles.label,
		] );

		const fieldClasses = classNames( [
			fieldClassName,
			styles.field,
			{ [ styles.simple ]: ! this.props.stylized },
		] );

		const wrapperClasses = classNames( [
			styles.wrapper,
			wrapperClassName,
		] );

		return (
			<div className={ fieldClasses }>
				<span className={ labelClasses }>
					{this.props.label}
				</span>
				<div className={ wrapperClasses }>
					{this.renderOptions()}
				</div>
			</div>
		);
	}
}

export default Toggle;

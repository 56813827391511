import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'Shortcuts/AddToEvent/components/Modal/ListItem.pcss';
import Color from 'AudienceListCreator/ListCreator/ListTitle/components/Colors/Color';
import overrideStyles from './ListItem.pcss';

const ListItem = ( {
	checked,
	id,
	title,
	color,
	subscriberCount,
	onChange,
} ) => {
	return (
		<div className={ classNames( 'form-control-radio', ' form-control-custom-style', overrideStyles.ListItem ) }>
			<div className={ overrideStyles.list }>
				<h3 className={ overrideStyles.title }>
					<Color color={ color } className={ overrideStyles.color } />
					<span>{ title }</span>
				</h3>
				<h4 className={ overrideStyles.subtitle }>
					{subscriberCount} Subscribers
				</h4>
			</div>
			<input className="form-control-radio__input" type="checkbox" name={ `add-to-event-${ id }` } value={ id } id={ `add-to-event-${ id }` } checked={ checked ? 'checked' : '' } onChange={ onChange } />
			{/* eslint-disable-next-line jsx-a11y/label-has-for */}
			<label className={ classNames( 'form-control-radio__label', styles.radio, overrideStyles.label ) } htmlFor={ `add-to-event-${ id }` }>
				<span className="a11y-visual-hide">{ title }</span>
			</label>
		</div>
	);
};

ListItem.propTypes = {
	checked: PropTypes.bool.isRequired,
	id: PropTypes.number.isRequired,
	subscriberCount: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default ListItem;

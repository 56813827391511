import { connect } from 'react-redux';
import Content from './content';
import * as ui from './ducks/ui';

export default connect(
	null,
	dispatch => ( {
		openSelected: () => dispatch( ui.openSelected() ),
		closeSelected: () => dispatch( ui.closeSelected() ),

	} )
)( Content );

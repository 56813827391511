import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Portal from 'common/Portal';
import styles from './Tooltip.pcss';

class Tooltip extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired,
		className: PropTypes.string,
		text: PropTypes.string.isRequired,
		hideMobile: PropTypes.bool,
		width: PropTypes.number,
	}

	static defaultProps = {
		className: '',
		hideMobile: false,
	}

	constructor( props ) {
		super( props );
		this.state = { visible: false };
		this.width = props.width || 150;
		if ( this.width === 'auto' ) {
			this.width = this.props.text.length * 8;
		}
		this.el = createRef();
	}

	clearDelayTimer = () => {
		clearTimeout( this.delayTimer );
		this.delayTimer = null;
	}

	delaySetVisible = ( visible, style = {} ) => {
		this.clearDelayTimer();

		this.delayTimer = setTimeout( () => {
			this.setState( {
				visible,
				style,
			}, this.clearDelayTimer() );
		} );
	}

	show = () => {
		const style = { width: this.width };
		const dimensions = this.el.current.getBoundingClientRect();

		style.left = ( dimensions.left + ( dimensions.width / 2 ) ) - ( this.width / 2 );
		style.left = Math.max( 0, style.left );
		style.left = Math.min( style.left, document.body.clientWidth - this.width );
		style.top = dimensions.bottom + window.pageYOffset;

		this.delaySetVisible( true, style );
	}

	hide = () => this.delaySetVisible( false );

	render() {
		const { children, className, text, hideMobile } = this.props;
		const { style, visible } = this.state;

		return (
			<i
				className={ className }
				onMouseEnter={ this.show }
				onMouseLeave={ this.hide }
				onFocus={ this.show }
				onBlur={ this.hide }
				onTap={ this.show }
				ref={ this.el }
			>
				{children}
				{visible && (
					<Portal>
						<div
							className={ classNames(
								styles.content,
								{ [ styles.hideMobile ]: hideMobile }
							) }
							style={ style }
							data-testid="tooltip"
						>
							{text}
						</div>
					</Portal>
				)}
			</i>
		);
	}
}

export default Tooltip;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectAllButton from './SelectAllButton';
import styles from './EventsHeader.pcss';

const EventsHeader = ( {
	timeFrame,
	onSelectAllClick,
	onDeselectAllClick,
	hasAllSelected,
	showTimeframe,
	hideSelectOnMobile,
} ) => {
	return (
		<div className={ classNames( styles.row ) }>
			<div className={ classNames( styles.timeFrame, {
				[ styles.show ]: showTimeframe,
			} ) }>{timeFrame}</div>
			<SelectAllButton
				onSelectAllClick={ onSelectAllClick }
				onDeselectAllClick={ onDeselectAllClick }
				hasAllSelected={ hasAllSelected }
				hideOnMobile={ hideSelectOnMobile }
			/>
		</div>

	);
};

EventsHeader.propTypes = {
	timeFrame: PropTypes.string.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	onDeselectAllClick: PropTypes.func.isRequired,
	hasAllSelected: PropTypes.bool.isRequired,
	showSelectButton: PropTypes.bool.isRequired,
	showTimeframe: PropTypes.bool,
	hideSelectOnMobile: PropTypes.bool,
};

export default EventsHeader;

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hideModal, getModalContent } from '../ducks';

export function mapStateToProps( state ) {
	return {
		content: getModalContent( state ),
	};
}

export function mapDispatchToProps( dispatch ) {
	return bindActionCreators( {
		hideModal,
	}, dispatch );
}

export default connect( mapStateToProps, mapDispatchToProps );

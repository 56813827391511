import React from 'react';
import PropTypes from 'prop-types';
import InfoBar from 'common/InfoBar';
import Warning from 'common/Warning';
import { __ } from 'utils/translations';
import CSVReader from './CSVReader';

const CSVSubscribers = ( {
	onFileLoaded,
	onError,
	showCSVFormatError,
} ) => (
	<>
		<InfoBar>
			For help setting up your CSV file please visit our <a href="https://support.theeventscalendar.com/559097-Add-subscribers">knowledgebase</a>.
		</InfoBar>
		<CSVReader label="Select File" onFileLoaded={ onFileLoaded } onError={ onError } />
		{ showCSVFormatError && (
			<Warning>{ __( 'warnings.errors-csv-format.required-fields' ) }</Warning>
		) }
	</>
);

CSVSubscribers.propTypes = {
	onFileLoaded: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
	showCSVFormatError: PropTypes.bool,
};

CSVSubscribers.defaultProps = {
	showCSVFormatError: false,
};

export default CSVSubscribers;

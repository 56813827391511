import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

class Portal extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired,
	}

	constructor( props ) {
		super( props );
		this.el = document.createElement( 'div' );
	}

	componentDidMount() {
		document.body.appendChild( this.el );
	}

	componentWillUnmount() {
		document.body.removeChild( this.el );
	}

	render() {
		return createPortal(
			this.props.children,
			this.el
		);
	}
}

export default Portal;

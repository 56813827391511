import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { HIDE_MODAL } from 'common/Modal/ducks';
import AddSubscribers from 'Modals/Subscribers/AddSubscribers';
import EditListSettings from 'Modals/Lists/EditListSettings';
import DeleteList from 'Modals/Lists/Delete';

export const MODALS_FOR_PAGE = {
	AddSubscribers,
	EditListSettings,
	DeleteList,
};

const [ ADD_SUBSCRIBERS_KEY, EDIT_LIST_SETTINGS_KEY, DELETE_LIST_KEY ] = Object.keys( MODALS_FOR_PAGE );

export const SHOW_ADD_SUBSCRIBERS = 'audiencelist/modals/SHOW_ADD_SUBSCRIBERS';
export const SHOW_EDIT_LIST_SETTINGS = 'audiencelist/modals/SHOW_EDIT_LIST_SETTINGS';
export const SHOW_DELETE_LIST = 'audiencelist/modals/SHOW_DELETE_LIST';

export const SUBMIT_SUBSCRIBERS = 'audiencelist/modals/SUBMIT_SUBSCRIBERS';
export const SUBMIT_EDIT_LIST_SETTINGS = 'audiencelist/modals/SUBMIT_EDIT_LIST_SETTINGS';
export const SUBMIT_DELETE_LIST = 'audiencelist/modals/SUBMIT_DELETE_LIST';

export const showAddSubscribers = createAction( SHOW_ADD_SUBSCRIBERS );
export const showEditListSettings = createAction( SHOW_EDIT_LIST_SETTINGS );
export const showDeleteList = createAction( SHOW_DELETE_LIST );
export const submitSubscribers = createAction( SUBMIT_SUBSCRIBERS );
export const submitEditListSettings = createAction( SUBMIT_EDIT_LIST_SETTINGS );
export const submitDeleteList = createAction( SUBMIT_DELETE_LIST );

export const INITIAL_STATE = {
	content: '',
};

export default function modalReducer( state = INITIAL_STATE, action ) {
	switch ( action.type ) {
		case SHOW_ADD_SUBSCRIBERS:
			return {
				...state,
				content: ADD_SUBSCRIBERS_KEY,
			};

		case SHOW_EDIT_LIST_SETTINGS:
			return {
				...state,
				content: EDIT_LIST_SETTINGS_KEY,
			};

		case SHOW_DELETE_LIST:
			return {
				...state,
				content: DELETE_LIST_KEY,
			};

		case HIDE_MODAL:
			return {
				...state,
				content: '',
			};

		default:
			return state;
	}
}

const getModalSlice = state => state.modal;
export const getModalContent = createSelector( getModalSlice, modal => modal.content );
export const getModalSubmitHandler = createSelector( getModalContent, modal => {
	switch ( modal ) {
		case ADD_SUBSCRIBERS_KEY:
			return submitSubscribers;
		case EDIT_LIST_SETTINGS_KEY:
			return submitEditListSettings;
		case DELETE_LIST_KEY:
			return submitDeleteList;
		default:
			return console.error;
	}
} );


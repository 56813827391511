import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import noop from 'lodash/noop';

export const SHOW_CONFIRM_MODAL = 'common/modal/show_confirm_modal';
export const SHOW_SCHEDULE_MODAL = 'common/modal/show_schedule_modal';
export const SHOW_DELETE_MODAL = 'common/modal/show_delete_modal';
export const SHOW_PREVIEW_MODAL = 'common/modal/show_preview_modal';
export const HIDE_MODAL = 'common/modal/hide';
export const SET_CONFIRM_ACTION = 'common/modal/set_confirm_action';

export const showConfirmModal = createAction( SHOW_CONFIRM_MODAL );
export const showScheduleModal = createAction( SHOW_SCHEDULE_MODAL );
export const showDeleteModal = createAction( SHOW_DELETE_MODAL );
export const showPreviewModal = createAction( SHOW_PREVIEW_MODAL );
export const hideModal = createAction( HIDE_MODAL );
export const setConfirmAction = createAction( SET_CONFIRM_ACTION );

export const INITIAL_STATE = {
	content: 'confirmSend',
	isOpen: false,
	onConfirmAction: noop,
};

export default function exampleReducer( state = INITIAL_STATE, action ) {
	switch ( action.type ) {
		case SHOW_CONFIRM_MODAL: {
			return {
				...state,
				isOpen: true,
				content: 'confirmSend',
			};
		}
		case SHOW_SCHEDULE_MODAL: {
			return {
				...state,
				isOpen: true,
				content: 'confirmSchedule',
			};
		}
		case SHOW_DELETE_MODAL: {
			return {
				...state,
				isOpen: true,
				content: 'confirmDelete',
			};
		}
		case SHOW_PREVIEW_MODAL: {
			return {
				...state,
				isOpen: true,
				content: 'previewMessage',
			};
		}
		case HIDE_MODAL: {
			return {
				...state,
				isOpen: false,
			};
		}
		case SET_CONFIRM_ACTION:
			return {
				...state,
				onConfirmAction: action.payload,
			};
		default:
			return state;
	}
}
export const modalSlice = state => state.modal;
export const getModalContent = createSelector( modalSlice, modal => modal.content );

export const getModalStyles = createSelector( getModalContent, content => {
	try {
		switch ( content ) {
			case 'previewMessage':
				return {
					overlay: {
						backgroundColor: window.modern_tribe_config.message_settings.user_settings.pageBgColor,
					},
				};

			default:
				return {};
		}
	} catch ( err ) {
		return {};
	}
} );

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import ListItem from './ListItem';

export default class Lists extends PureComponent {
	static propTypes = {
		lists: PropTypes.arrayOf( PropTypes.object ),
		selected: PropTypes.arrayOf( PropTypes.number ),
		onChange: PropTypes.func.isRequired,
	}

	static defaultProps = {
		lists: [],
	}

	onChange = ( e ) => {
		this.props.onChange( Number( e.target.value ) );
	}

	render() {
		return this.props.lists.map( list => (
			<ListItem
				key={ list.id }
				id={ list.id }
				title={ list.title }
				color={ list.color }
				subscriberCount={ list.subscribers.length }
				checked={ includes( this.props.selected, list.id ) }
				onChange={ this.onChange }
			/>
		) );
	}
}

import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { UPDATE_TITLE, UPDATE_COLOR, UPDATE_FOOTER } from 'AudienceListCreator/ListCreator/ducks';

export const SET_ACTIVE_LIST = 'audiencelist/lists/SET_ACTIVE_LIST';

export const setActiveList = createAction( SET_ACTIVE_LIST );

export const INITIAL_STATE = {
	id: 0,
	title: '',
};

export default function listReducer( state = INITIAL_STATE, action ) {
	switch ( action.type ) {
		case SET_ACTIVE_LIST:
		case UPDATE_TITLE:
		case UPDATE_FOOTER:
		case UPDATE_COLOR:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
}

const getListSlice = state => state.list;
export const getListId = createSelector( getListSlice, list => list.id );
export const getListTitle = createSelector( getListSlice, list => list.title );
export const getListFooter = createSelector( getListSlice, list => list.footer );
export const getListColor = createSelector( getListSlice, list => list.color );

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import SimpleValue from 'react-select-simple-value';
import styles from './Select.pcss';
import classNames from 'classnames';

const getChildren = ( { children, data } ) => {
	if ( data.color ) {
		return (
			<span className={ styles.option }>
				{
					!! data.color && (
						<span className={ styles.color } style={ { backgroundColor: data.color } } ></span>
					)
				}
				{ children }
			</span>
		);
	}

	return <span dangerouslySetInnerHTML={ { __html: children } } />;
};

const SingleValue = ( { children, ...props } ) => (
	<components.SingleValue { ...props }>
		<span dangerouslySetInnerHTML={ { __html: children } } />
	</components.SingleValue>
);

const Option = ( props ) => {
	return (
		<components.Option { ...props } children={ getChildren( props ) } />
	);
};

SingleValue.propTypes = Option.propTypes = {
	children: PropTypes.oneOfType( [ PropTypes.node, PropTypes.string ] ),
};

getChildren.propTypes = {
	children: PropTypes.oneOfType( [ PropTypes.node, PropTypes.string ] ),
	data: PropTypes.shape( {
		color: PropTypes.string,
	} ),
};

export default class SelectMod extends Component {
	static propTypes = {
		name: PropTypes.string.isRequired,
		options: PropTypes.array.isRequired,
		onChange: PropTypes.func.isRequired,
		isActive: PropTypes.bool.isRequired,
		label: PropTypes.string,
		mode: PropTypes.string,
		value: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
		setting: PropTypes.string,
		isSearchable: PropTypes.bool,
	};

	static defaultProps = {
		label: '',
		mode: 'large',
		name: '',
		options: [],
		value: '',
		isActive: true,
		isSearchable: false,
	};

	onChange = ( e ) => {
		this.props.onChange( e.value );
	};

	arrowRender = () => {
		return (
			<span className={ styles.arrowDrop }>Test</span>
		);
	};

	render() {
		const { name, options, value, isActive, mode, label, isSearchable } = this.props;

		if ( ! isActive || ! options || ! options.length ) {
			return null;
		}

		const customStyles = {
			control: ( provided ) => ( {
				...provided,
				'height': 48,
				'minWidth': 150,
				'fontWeight': 500,
				'fontSize': 14,
				'borderColor': '#d0d2d3',
				'boxShadow': 'none',
				'borderRight': this.props.setting === 'groupedLeft' ? '0' : '1px solid #d0d2d3',
				'borderTopLeftRadius': this.props.setting === 'groupedRight' ? '0' : '4px',
				'borderBottomLeftRadius': this.props.setting === 'groupedRight' ? '0' : '4px',
				'borderTopRightRadius': this.props.setting === 'groupedLeft' ? '0' : '4px',
				'borderBottomRightRadius': this.props.setting === 'groupedLeft' ? '0' : '4px',

				':hover': {
					borderColor: '#d0d2d3',
				},

				':active': {
					borderColor: '#008cff',
				},

				':focus': {
					borderColor: '#008cff',
				},
			} ),
			valueContainer: ( provided ) => ( {
				...provided,
				padding: '2px 14px',
			} ),
			indicatorSeparator: () => ( {
				diplay: 'none',
			} ),
			input: () => ( {
				opacity: 1,
				border: '0',
				boxShadow: 'none',
			} ),
			placeholder: ( provided ) => ( {
				...provided,
				color: '#0F1031',
				fontSize: 14,
				fontWeight: 500,
			} ),
			dropdownIndicator: ( provided ) => ( {
				...provided,
				color: '#0F1031',
				fontSize: 14,
				fontWeight: 500,
			} ),
			menu: ( provided ) => ( {
				...provided,
				marginTop: '-2px',
				padding: '2px 6px',
				width: '100%',
				border: '1px solid #008cff',
				borderTopLeftRadius: '0',
				borderTopRightRadius: '0',
				boxShadow: '0',
			} ),
			option: ( provided, { isFocused, isSelected } ) => ( {
				...provided,
				color: isFocused ? '#008cff' : isSelected ? '#008cff' : '#0F1031', //eslint-disable-line
				background: 'transparent',
				fontSize: 14,
				fontWeight: 500,
			} ),
		};

		const wrapperClasses = classNames( {
			[ styles.compact ]: mode === 'compact',
			[ styles.field ]: mode === 'compact',
			promoter__select: true,
		} );

		const labelClasses = classNames( {
			[ styles.label ]: true,
			'promoter__select-label': true,
		} );

		return (
			<div className={ wrapperClasses }>
				{label && <span className={ labelClasses }>{label}</span>}
				<SimpleValue options={ this.props.options } value={ value }>
					{simpleProps =>
						<Select
							className={ styles.selectInput }
							classNamePrefix="reactSelect"
							styles={ customStyles }
							arrowRenderer={ this.arrowRender }
							name={ name } id={ name }
							onChange={ this.onChange }
							isSearchable={ isSearchable }
							components={ { Option, SingleValue } }
							{ ...simpleProps }
						/>}
				</SimpleValue>
			</div>
		);
	}
}

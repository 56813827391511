import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import classNames from 'classnames';
import styles from './Button.pcss';

export default class Button extends Component {
	static propTypes = {
		label: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		onClick: PropTypes.func.isRequired,
		className: PropTypes.string,
		type: PropTypes.string,
		bare: PropTypes.bool,
		isActive: PropTypes.bool,
		isVisible: PropTypes.bool,
	};

	static defaultProps = {
		label: '',
		name: '',
		className: '',
		type: 'primary',
		bare: false,
		isActive: true,
		isVisible: true,
	};

	onClick = ( e ) => {
		e.preventDefault();
		this.props.onClick();
	};

	render() {
		const { label, name, className, type, isActive, isVisible, bare } = this.props;
		const rest = omit( this.props, [ 'bare', 'isActive', 'isVisible', 'label', 'type', 'data-testid' ] );
		const buttonClasses = classNames( {
			[ styles.button ]: true,
			[ styles.bare ]: bare,
			[ styles.primary ]: type === 'primary',
			[ styles.blue ]: type === 'blue',
			[ styles.blueTransparent ]: type === 'blue-transparent',
			[ styles.secondary ]: type === 'secondary',
			[ styles.tertiary ]: type === 'tertiary',
			[ styles.tertiaryBlue ]: type === 'tertiary-blue',
			[ styles.inline ]: type === 'inline',
			[ styles.preview ]: type === 'preview',
			[ styles.delete ]: type === 'delete',
			[ styles.duplicate ]: type === 'duplicate',
			[ styles.revert ]: type === 'revert',
			[ styles.red ]: type === 'red',
			[ styles.redOutline ]: type === 'red-outline',
			[ styles.green ]: type === 'green',
			[ styles.blueSmall ]: type === 'blue-small',
			[ styles[ 'btn--disabled' ] ]: ! isActive,
		}, className );

		if ( ! isVisible ) {
			return null;
		}

		return (
			<button
				{ ...rest }
				className={ buttonClasses }
				name={ name }
				onClick={ this.onClick }
				disabled={ ! isActive }
			>
				{ label }
			</button>
		);
	}
}

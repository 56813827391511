import React from 'react';
import PropTypes from 'prop-types';
import Color from './Color';
import { LIST_EDITOR_SETTINGS } from 'config/settings';

const Colors = ( { colors, activeColor, updateColor } ) => {
	return (
		colors.map( color => (
			<Color key={ color } color={ color } isActive={ color === activeColor } onClick={ () => updateColor( color ) } />
		) )
	);
};

Colors.defaultProps = {
	colors: LIST_EDITOR_SETTINGS.colors,
};

Colors.propTypes = {
	colors: PropTypes.arrayOf( PropTypes.string ),
	activeColor: PropTypes.string,
	updateColor: PropTypes.func.isRequired,
};

export default Colors;

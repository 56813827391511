import { createSelector } from 'reselect';
import { difference } from 'lodash';
import { hasSearchText } from 'Modals/Subscribers/CopySubscribers/ducks/ui';
import { getAll, getSearchedIds, getSelected } from './lists';

export const getActiveListIds = createSelector(
	[ hasSearchText, getAll, getSearchedIds ],
	( isSearching, allLists, searchedLists ) => {
		if ( isSearching ) {
			return searchedLists;
		}
		return Object.keys( allLists ).map( Number );
	}
);

export const getActiveLists = createSelector( [ getAll, getActiveListIds ], ( all, activeIds ) => activeIds.map( id => all[ id ] ) );
export const hasSelectedAllActiveLists = createSelector( [ getActiveListIds, getSelected ], ( active, selected ) => {
	return selected.length > 0 && ! difference( active, selected ).length;
} );

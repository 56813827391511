import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalHeader from './ModalHeader';
import styles from './modal.pcss';

const ModalContent = ( {
	children,
	className,
	hideModal,
	title,
	id,
} ) => {
	return (
		<section id={ id } className={ classNames( styles.modal, className ) }>
			<ModalHeader title={ title } hideModal={ hideModal } />
			<div className={ classNames( styles.modal__content, 'modal__content' ) }>
				<div className={ styles.inner }>
					{ children }
				</div>
			</div>
		</section>
	);
};

ModalContent.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	hideModal: PropTypes.func,
	title: PropTypes.string,
	id: PropTypes.string,
};

export default ModalContent;
